.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  opacity: 0.85;
  // background: #000;
  .face {
    position: absolute;
    border: 1px solid #12121240;
    .circle {
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      width: 50%;
      height: 2px;
      //   background: #fff;
      transform-origin: left;
    }
    .circle::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      top: -2px;
      right: -4px;
    }
  }
  .face1 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: #090909;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    border-radius: 50%;
    border-left: 1px solid #2878ff;
    border-top: 1px solid #2878ff;
    animation: animate 3s linear infinite;
    .circle {
      transform: rotate(-45deg);
    }
    .circle::before {
      background: #2878ff;
      box-shadow: 0 0 20px #2878ff, 0 0 40px #2878ff, 0 0 60px #2878ff,
        0 0 80px #2878ff, 0 0 100px #2878ff, 0 0 0 5px rgba(255, 255, 0, 0.1);
    }
  }
  .face2 {
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    // background: #090909;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    border-radius: 50%;
    border-right: 1px solid #1ec0ff;
    border-bottom: 1px solid #1ec0ff;
    animation: animate 3s linear reverse infinite;
    .circle {
      transform: rotate(-45deg);
    }
    .circle::before {
      background: #1ec0ff;
      box-shadow: 0 0 20px #1ec0ff, 0 0 40px #1ec0ff, 0 0 60px #1ec0ff,
        0 0 80px #1ec0ff, 0 0 100px #1ec0ff, 0 0 0 5px rgba(3, 244, 236, 0.1);
    }
  }
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
