.contentside {
  visibility: visible;
  position: absolute;
  top: 85px;
  right: 18px;
  z-index: 99;
  .options {
    list-style: none;
    margin-top: 6px;

    .option {
      display: flex;
      cursor: pointer;
      margin-bottom: 10px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.65;
    }
    .option:hover {
      background-color: #66d2f925;
      transition: 0.3s;
      opacity: 0.85;
    }
    .option img {
      width: 20px;
      height: 20px;
      opacity: 1;
    }
  }
  .options.part2 {
    margin-top: 75px;
  }
  
}
