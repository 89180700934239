.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9ca2b50b; /* 半透明黑色背景 */
  // background-color: rgba(0, 0, 0, 0.025); /* 半透明黑色背景 */
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  // display: none;
  z-index: 100;
}
.overlay.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
  // display: block;
  z-index: 100;
}

.popuplayout-tool {
  position: absolute;
  box-sizing: border-box;
  z-index: 107;
  padding: 0;
  min-width: 0;
  font-size: 14px;
  top: 74px;
  right: 58px;
  min-width: 160px;
  //   height: 326px;
  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  background-color: #fff;
  overflow: hidden;
  //   box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  box-shadow: 0px 3px 8px 4px rgb(17 71 162 / 3%),
    0px 3px 8px 4px rgb(17 71 162 / 3%);

  //   opacity: 1;
  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  .popupbody.tool {
    align-items: center;
    width: 100%;
    position: relative;
    padding: 8px 0 8px;
    .tool-list {
      display: flex;
      height: 36px;
      padding: 10px 20px;
      color: #6c757d;
      cursor: pointer;
      //   border-bottom: 1px solid #eaeaeabb;
      //   justify-content: space-between;
      .listicon {
        opacity: 0.65;
        .toolicon {
          width: 18px;
        }
      }
      .list-name {
        font-size: 13px;
        margin-left: 8px;
        // font-weight: 500;
        // letter-spacing: 1px;

        opacity: 0.8;
      }
      //   .list-name:hover {
      //     color: #1876f2;
      //   }
    }
    .tool-list:hover {
      color: #1876f2;
    }
  }
  .listshow {
    align-items: center;
    width: 100%;
    position: relative;
    padding: 8px 0 8px;
    .tool-list {
      display: flex;
      height: 36px;
      padding: 10px 20px;
      border-bottom: 1px solid #eaeaeabb;
      justify-content: space-between;
      .returnbtn {
        opacity: 0.65;
        // width: 32px;
        // height: 32px;
        // border-radius: 50%;
        // background-color: #e5ebf349;
        cursor: pointer;
        .returnicon {
          width: 18px;
        }
      }
      .listicon {
        opacity: 0.55;
        .toolicon {
          width: 18px;
        }
      }
    }
    .tool-listitem {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      margin-top: 6px;
      .list-item {
        height: 28px;
        font-size: 12px;
        margin-left: 0px;
        // font-weight: 500;
        // letter-spacing: 1px;
        color: #6c757d;
        opacity: 0.8;
        cursor: pointer;
      }
      .list-item:hover {
        color: #1876f2;
      }
      .list-item.active {
        color: #1876f2;
        text-decoration: underline;
      }
    }
    .tool-publishlistitem,
    .tool-functionlistitem{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 5px;
      padding: 10px 20px;
      width: 180px;
      .item-container {
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        margin-bottom: 10px;
        height: 28px;
        .select-item {
          display: flex;
          width: 85px;
          align-items: center;
          font-size: 12px;
          margin-left: 0px;
          // background-color: rgb(154, 66, 66);
          // font-weight: 500;
          // letter-spacing: 1px;
          color: #6c757d;
          opacity: 0.8;
          // margin-right: 10px;
        }

        .checkbox-container {
          display: flex;
          align-items: center; // 水平居中对齐
          position: relative;

          input[type="checkbox"] {
            // position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked + label {
              background: #1876f2d7;
              // background: #387cfa;
              &::before {
                left: 18px;
              }
            }
          }

          label {
            margin-left: 6px; // 右侧的间距
            position: relative;
            height: 18px;
            width: 36px;
            background-color: #eaeaeabb;
            // box-shadow: inset 0 0 5px rgba(0, 9, 130, 0.16);
            outline: none;
            cursor: pointer;
            border-radius: 20px;
            transition: 0.5s;

            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              border-radius: 20px;
              top: 0;
              left: 0;
              transform: scale(0.85);
              // background: #fefefe;
              background: linear-gradient(#fff, #f8f8f8, #e9e9e9, #e6e6e6);
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18);
              transition: 0.5s;
            }
          }
        }
      }
      .password-input-container {
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 6px;
        .password-input {
          color: #6c757dd0;
          padding-left: 5px;
          border: none;
          border-bottom: 0.5px solid #eaeaeabb;
          width: 80%;
          font-size: 12px;
        }
        .password-toggle {
          opacity: 0.8;
          margin-left: auto;
          margin-right: 2px;
          cursor: pointer;
          .toolicon {
            width: 20px;
          }
        }
        .password-input::placeholder {
          color: rgb(195, 195, 195);
          font-size: 12px;
        }
      }
      .button-group {
        display: flex;
        justify-content: center;
        .pwaddbutton,
        .pwclearbutton {
          width: 48px;
          height: 24px;
          border: none;
          margin: 15px 12px 8px 12px;
          border-radius: 2px;
          color: #6c757d;
          font-size: 11px;
          cursor: pointer;
        }
        .pwaddbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #1876f2d7;
        }

        .pwclearbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #f3376b;
        }
      }
    }
  }
}

.notice-lists::-webkit-scrollbar {
  width: 6px;
}
.notice-lists::-webkit-scrollbar-track {
  // background: #f1f1f1;
  border-radius: 25px;
}
.notice-lists::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 25px;
  border: 0.5rem solid transparent;
}
.notice-lists::-webkit-scrollbar-thumb:hover {
  background: rgba(204, 204, 204, 0.38);
}

.popuplayout-tool.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-15px);
  transition: 0.2s;
  z-index: 107;
}
