.model-layout {
  z-index: 6;
  // background-color: #ffffff;
  // background-color: red;
  .diagram-component {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border: none;

    //  background-color: #f7f8fa;
    //  background-color: rgba(80,185,254,0.2);
    //  background-color: rgba(255,255,255,0.05);
  }
}
