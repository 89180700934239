.overlayfile {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbfbfb; /* 半透明黑色背景 */
  opacity: 0.6;
  visibility: visible;
  transition: 0.3s ease;
  z-index: 100;
}

.filesearchBox {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 410px;
  height: 50px;
  border-radius: 25px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
  z-index: 101;
  .search {
    position: relative;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    .searchimg {
      width: 24px;
      height: 24px;
      opacity: 0.65;
      scale: 0.85;
    }
    .searchimg:hover {
      opacity: 0.85;
      scale: 0.9;
    }
  }

  .searchInput {
    position: absolute;
    left: 45px;
    width: calc(100% - 120px);

    height: 70%;
    line-height: 60px;
    .inputkey {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      padding: 5px;
      border: none;
      outline: none;
      font-size: 14px;
      opacity: 0.75;
    }
    .inputkey::placeholder {
      opacity: 0.6;
    }
  }

  .searchright {
    display: flex;
    align-items: center;
    .scanner {
      position: relative;
      width: 30px;
      height: 30px;
      right: -10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // font-size: 1.25em;
      scale: 0.9;
      // transition: 0.3s;
      opacity: 0.5;
      .scannerimg {
        width: 24px;
        height: 24px;
      }
    }
    .scanner:hover {
      scale: 0.95;
      opacity: 0.75;
    }
    .close {
      position: relative;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // font-size: 1.25em;
      scale: 0.65;
      // transition: 0.3s;
      opacity: 0.55;
      .closeimg {
        width: 24px;
        height: 24px;
      }
    }
    .close:hover {
      scale: 0.85;
      opacity: 0.9;
    }
  }
}
.filesearchBox:hover {
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.2);
  scale: 1.005;
}

.filesearchBox .searchInput .filesearchBox .searchInput input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.searchcontent {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  min-width: 400px;
  max-width: 1180px;
  height: calc(100% - 200px);
  border-radius: 8px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  overflow: hidden;
  //   box-shadow: 0 2px 10px rgba(16, 40, 255, 0.2);
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
  z-index: 101;
  // background-color: rgba(253, 253, 253, 0.9);
  // position: relative;
  .contentlayout {
    position: absolute;
    left: 3%;
    right: 10px;
    width: 94%;
    height: 86%;
    top: 50%;
    padding: 10px 0 20px 0;
    transform: translateY(-50%);
    overflow-y: auto;
    overflow-x: hidden;

    .contentitems {
      display: flex;
      padding-bottom: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 12px;
      width: 100%;
      height: 100%;

      .loadingmessage {
        padding-left: 20px;
        font-size: 14px;
        color: #1876f2cb;
        img {
          width: 36px;
          margin-right: 10px;
        }
      }
      .noresults {
        padding-left: 20px;
        font-size: 14px;
        color: #1876f2cb;
        img {
          width: 32px;
          margin-right: 10px;
        }
      }

      .fileitemslayout {
        // margin-bottom: 10px;
        background-color: rgba(243, 244, 255, 0.4);
        // background-color: rgb(255, 255, 255);
        border: 1px solid #eaeaeabb;
        border-radius: 6px;
        width: 31%;
        height: 290px;
        // margin: 2px;
        .fileitemshow {
          //   background-color: rgb(108, 235, 35);
          width: 100%;
          height: 200px;
        }
        .fileitemname {
          display: flex;
          //   background-color: aqua;
          width: 100%;
          height: 40px;
          border-top: 1px solid #eaeaeabb;
          align-items: center;
          padding-left: 10px;
          font-size: 13px;
          color: #8d8d8df0;
          cursor: pointer;
          text-decoration: none;
          //   opacity: 0.85;
        }
        .fileitemname:hover {
          color: #1876f2;
          // text-decoration: underline;
        }
        .fileiteminfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   background-color: rgb(195, 233, 43);
          width: 100%;
          height: 40px;
          .fileinfolike,
          .fileinfokeep {
            display: flex;
            align-items: center;
            width: 16px;
            margin: 0 10px;
            opacity: 0.6;
            img {
              width: 18px;
            }
          }

          .filelikenum,
          .filekeepnum {
            display: flex;
            align-items: center;
            color: #8d8d8dab;
            font-size: 13px;
          }

          .fileinfouser {
            display: flex;
            margin-left: auto;
            margin-right: 16px;
            //   width: 20px;
            .fileuserimage {
              width: 26px;
              border-radius: 50%;  
          }
          }
        }
      }
      .fileitemslayout:hover {
        box-shadow: 0 2px 10px rgba(183, 183, 183, 0.25);
        scale: 1.005;
      }
    }
  }

  @media (max-width: 1200px) {
    .contentlayout .contentitems {
      .fileitemslayout {
        width: 46%;
      }
    }
  }
  @media (max-width: 500px) {
    .contentlayout .contentitems {
      .fileitemslayout {
        width: 100%;
      }
    }
  }
}

@media (max-width: 420px) {
  .filesearchBox {
    width: 88%;
  }
  .searchcontent {
    min-width: 86%;
  }
}
