.chatcard {
  width: 330px;
  max-height: 700px;
  background: #fff;
  position: fixed;
  top: 90px;
  left: 20px;
  // box-shadow: 0px 6px 15px 4px rgb(17 71 162 / 5%);
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
  // backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 10px;
  min-width: 0;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  .chathead {
    display: flex;
    justify-content: space-between;
    cursor: grab;
    // border-bottom: 0.5px solid #eaeaeabb;
    // border-bottom: 0.5px solid #eaeaea81;
    .chatdraggabletitle {
      display: flex;
      font-size: 13px;
      // width: 158px;
      // min-height: 20px;
      // max-width: 158px;
      color: #3469e6;
      // background-color: #f0f0f0;
      padding: 5px;
      opacity: 0.8;
      cursor: pointer;
      word-wrap: break-word;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-word;
      transition: height 0.3s ease;
      will-change: height;
      transform: translateZ(0);
      // display: none;
    }
    .chatdraggabletitle:hover {
      color: #1b54d9;
      opacity: 1;
    }
    .closeicon {
      opacity: 0.4;
      width: 18px;
      cursor: pointer;
    }
    .closeicon:hover {
      opacity: 0.8;
    }
  }
  .chatusers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 0.5px solid #eaeaeabb;
    // background-color: rgb(249, 186, 186);
    .usericons {
      display: flex;
      //   align-items: center;
      .usericon {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #1971f6;
        width: 40px;
        height: 40px;
        // border: 2px solid #ffffff;
        border-radius: 20px;
        margin-right: -10px;
        transition: z-index 0.3s ease;
        cursor: pointer;
        .strangeavatar {
          width: 34px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          background-color: #fff;
        }
        .strangeavatar:hover {
          border: none;
        }
      }
      .usericon:hover {
        // border: 2px solid #1971f6;
        // background-color: #66d2f925;
        background-color: #e3f4ff;
        transition: 0.3s;
        z-index: 1;
        // opacity: 0.85;
      }
    }
  }
  .chatslayout {
    display: flex;
    flex-direction: column;
    // background-color: #6c757d15;
    width: 100%;
    height: 250px;
    border-radius: 8px;
    padding: 8px 6px 8px 6px;
    overflow-y: auto;

    .replyContainer {
      display: flex;
      justify-content: flex-end; /* 默认情况下内容靠右 */
      //   margin-bottom: 6px;
      margin-top: 10px;
      .chatreply {
        display: flex;
        .replyleft {
          margin-right: 6px;
          .replyusericon {
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #f62419;
            width: 36px;
            height: 36px;
            border: 2px solid #fff;
            border-radius: 18px;
            cursor: pointer;
            .strangeavatar {
              width: 28px;
              border-radius: 50%;
              background-color: #fff;
            }
          }
          .replyusericon:hover {
            background-color: #66d2f925;
            transition: 0.3s;
            opacity: 0.85;
          }
        }
        .replyright {
          .replyuser {
            display: flex;
            .replyusername {
              // display: flex;
              margin-right: 8px;
              font-size: 12px;
              color: #6c757d;
            }
            .replyuserunit {
              display: flex;
              align-items: center;
              font-size: 10px;
              color: #6c757d;
            }
          }
          .replycontent {
            background-color: #1971f6;
            margin-top: 6px;
            padding: 7px 14px 7px 14px;
            border-radius: 6px;
            font-size: 12px;
            color: #ffffff;
            //   color: #1f2129;
            width: fit-content;
            max-width: 236px;
            word-wrap: break-word;
          }
          .replycontent:hover {
            background-color: #3469e6;
          }
          .replykewords {
            display: inline-block;
            border: 0.5px solid #1971f6;
            margin-top: 6px;
            padding: 3px 6px 3px 6px;
            border-radius: 4px;
            font-size: 10px;
            color: #1971f6;
            max-width: 200px;
            word-wrap: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .replykewords:hover {
            background-color: #f8f8f8;
          }
        }
      }
      .chatanswer {
        //   margin-left: auto;
        align-self: flex-end;
        .answerleft {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .answercontent {
            background-color: #f8f8f8;
            padding: 7px 14px 7px 14px;
            border-radius: 6px;
            font-size: 12px;
            //   color: #ffffff;
            color: #1f2129;
            width: fit-content;
            max-width: 250px;
            word-wrap: break-word;
          }
          .answercontent:hover {
            background-color: #f4f4f4;
          }
          .answerkewords {
            display: inline-block;
            border: 0.5px solid #6c757d;
            margin-top: 6px;
            padding: 3px 6px 3px 6px;
            border-radius: 4px;
            font-size: 10px;
            color: #6c757d;
            opacity: 0.85;
            max-width: 200px;
            word-wrap: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .answerkewords:hover {
            background-color: #f8f8f8;
          }
        }
        .answerright {
        }
      }
    }

    .leftContent {
      display: flex;
      justify-content: flex-start; /* 内容靠左 */
    }
    .replyleft {
      margin-right: 8px; /* 为了间隔 replyleft 和 replyright */
    }
  }

  .chatsend {
    border-top: 0.5px solid #eaeaeabb;
    height: 60px;
    // background-color: rgb(235, 249, 234);
    .chatinput {
      margin: 8px 8px 8px 8px;
      border: none;
      width: 98%;
      height: 100%;
      resize: none;
      color: #1f2129;
    }
    .chatinput::placeholder {
      color: #a8bacd;
      font-size: 12px;
      opacity: 0.8;
    }
  }
  .chatbtn {
    display: flex;
    justify-content: space-between;
    margin: 16px 0 8px 0;
    cursor: grab;
    // background-color: rgb(248, 170, 170);
    .leftbtn {
      display: flex;
      .iconicon {
        margin-left: 3px;
        margin-right: 15px;
        cursor: pointer;
        opacity: 0.7;
        width: 22px;
      }
      .iconicon:hover {
        opacity: 1;
      }
      .linkicon {
        cursor: pointer;
        opacity: 0.7;
        width: 22px;
      }
      .linkicon:hover {
        opacity: 1;
      }
    }
    .rightbtn {
      display: flex;
      .sendbtn {
        display: flex;
        background-color: #f8f8f8;
        width: 52px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        .sendicon {
          opacity: 0.85;
          width: 16px;
        }
      }
      .sendbtn:hover {
        background-color: #f0f0f0;
      }
      .moreicon {
        margin-right: -3px;
        margin-left: 2px;
        cursor: pointer;
        opacity: 0.7;
        width: 22px;
      }
      .moreicon:hover {
        opacity: 1;
      }
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 100%;
    // cursor: pointer;
    .morebtn {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 100%;

    .morebtn {
      margin-top: 10px;
      cursor: pointer;
      opacity: 0.7;
    }
    .morebtn:hover {
      opacity: 1;
    }
  }
}

.chatslayout::-webkit-scrollbar {
  width: 6px; /* 设置滚动条宽度 */
}
.chatslayout::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.chatslayout::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.chatinput::-webkit-scrollbar {
  width: 6px; /* 设置滚动条宽度 */
}
.chatinput::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.chatinput::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.chatcard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.1s;
  z-index: 901;
}

@media (max-width: 760px) {
  .datacard.active {
    .datahead {
      // height: 8px;
      // background-color: rgb(203, 196, 196);
      cursor: pointer;
      .datadraggabletitle {
        // opacity: 0;
        // display: none;
        // height: 20px;
        font-size: 11px;
        color: #6c757d;
      }
      .closeicon {
        // display: none;
      }
    }
    .dataitems {
      border: none;
      .nodedatalist {
        margin-top: 0px;
        margin-bottom: 6px;
        label {
          // font-size: 11px;
          display: none;
        }
        .nodedataitem {
          .datatitle {
            font-size: 11px;
          }
          .datadetail {
            font-size: 12px;
          }
        }
      }
    }
  }
}
