.controlinfocard {
  width: 400px;
  max-height: 700px;
  background: rgba(255, 255, 255, 0.88);
  position: fixed;
  top: 90px;
  left: 20px;
  //   background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  // box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 15px;
  min-width: 0;
  font-size: 14px;

  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  .controlhead,
  .controltitle,
  .controlbuttons,
  .controlline {
    display: flex;
    font-size: 14px;
    color: #393c48;
    justify-content: space-between;
    // border-bottom: 0.5px solid #eaeaeabb;
    padding: 5px;
    .lineitems {
      width: 100%;
      .linepic {
        display: flex;
        align-items: center;
        height: 38px;
        .timeslider {
          border: none; /* 设置无边框 */
          outline: none; /* 去掉默认的聚焦边框 */
          -webkit-appearance: none; /* 去掉默认的样式 */
          width: 100%; /* 设置滑动条的宽度 */
          height: 4px; /* 设置滑动条的高度 */
          background-color: #eaf0ff; /* 设置滑动条的背景颜色 */
          border-radius: 2px; /* 设置滑动条的边框半径 */
        }

        /* 调整滑动条的进度条样式 */
        .timeslider::-webkit-slider-thumb {
          -webkit-appearance: none; /* 去掉默认样式 */
          width: 12px; /* 设置进度条的宽度 */
          height: 12px; /* 设置进度条的高度 */
          background-color: #66c9f9; /* 设置进度条的颜色 */
          border-radius: 50%; /* 设置进度条的边框半径 */
        }
      }
      .linedesc {
        font-size: 8px;
        height: 36px;
        color: #6c757d;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .timeinput {
        }
        .bottombotton {
          display: flex;
          align-items: center;
          // cursor: pointer;
          .morebtn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .controlinputs {
    margin: 8px 0;
    .cycletimes,
    .agreetimes {
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: space-between;
      .timetitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #66c8f9;
        opacity: 0.85;
      }
      .spacecenter {
        width: 10px;
      }
      .timeinput {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        font-size: 12px;
        width: 150px;
        border-bottom: 1px solid #eaeaeabb;
      }
      .timeinput2 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        font-size: 12px;
        width: 80px;
        border-bottom: 1px solid #eaeaeabb;
      }
    }
  }
  .controlhead {
    .headstatus {
      display: flex;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.859);
      width: 180px;
      height: 30px;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
      background-color: #356ae6;
    }
    .headicons {
      display: flex;
      align-items: center;
      .headalarm,
      .headinfo {
        display: flex;
        align-items: center;
        width: 16px;
        margin-right: 28px;
        opacity: 0.75;
        cursor: pointer;
        .headicon {
          width: 18px;
        }
        .headnum{
        font-size: 10px;
        margin-left: 8px;
        }
      }
    }
    .headcancel {
      // width: 16px;
      opacity: 0.4;
      cursor: pointer;
      .closeicon {
        width: 18px;
      }
    }
    .headcancel:hover {
      opacity: 0.8;
    }
  }
  .controltitle {
    font-size: 13px;
    .nodedesc {
      display: flex;
      align-items: center;
      height: 38px;
      opacity: 0.8;
    }
    .controlop {
      display: flex;
      align-items: center;
      opacity: 0.8;
    }
  }
  .controlbuttons {
    display: flex;
    align-items: center;
    height: 45px;
    .runicon,
    .pauseicon,
    .stopicon {
      height: 50px;
      cursor: pointer;
    }
    .timeshow {
      align-items: center;
      color: #6c757d;
      font-size: 14px;
      border-left: 0.5px solid #eaeaeabb;
      padding: 12px;
      opacity: 0.8;
      .realsdown,
      .agreesdown,
      .legalsdown {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
      }
    }
    .timeshow.real {
      color: #19cca3;
    }
    .timeshow.agree {
      color: #7733ff;
    }
    .timeshow.legal {
      color: #1b54d9;
    }
  }
}

.controlinfocard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.2s;
  z-index: 901;
}
