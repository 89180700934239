.qrcodecard {
  width: 100px;
  height: 120px;
  background: #fff;
  position: fixed;
  top: 80px;
  right: 56px;
  //   background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 6px 2px rgba(17, 70, 162, 0.08);
  //   box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(25px);
  z-index: 9998;

  // position: absolute;
  box-sizing: border-box;
  padding: 5px 6px;
  font-size: 14px;

  border-radius: 18px;
  border: 1px solid #eaeaeabb;
  overflow: hidden;

  .qrcodepic {
    margin-top: 2px;
    width: 80px;
    height: 80px;
    margin-left: 4px;
    opacity: 0.8;
  }
  .qrcodebuttons {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    width: 80%;
    .qrcodebutton {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      width: 18px;
      height: 18px;
      border-radius: 15px;
      background-color: transparent;
      cursor: pointer;

      .clouddownload {
        width: 19px;
        opacity: 0.45;
      }
      .clouddownload:hover {
        opacity: 0.8;
      }
      .closeButton2 {
        width: 12px;
        opacity: 1;
      }
    }

    .qrcodebutton.close {
      background-color: #fe2f6a76;
    }

    .qrcodebutton.close:hover {
      background-color: #fe2f6acb;
    }
  }
}
