@media (max-width: 650px) {
  #op-item {
    display: none;
  }
}

@media (max-width: 450px) {
  #op-item {
    display: none;
  }
  #op-item-logo {
    display: none;
  }
  #op-item-filename {
    // display: none;
    max-width: 50px;
  }
}

.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9ca2b500; /* 半透明黑色背景 */
  // background-color: rgba(0, 0, 0, 0.025); /* 半透明黑色背景 */
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  // display: none;
  z-index: 100;
}
.overlay1.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
  // display: block;
  z-index: 100;
}
.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9ca2b500; /* 半透明黑色背景 */
  // background-color: rgba(0, 0, 0, 0.025); /* 半透明黑色背景 */
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  // display: none;
  z-index: 100;
}
.overlay2.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
  // display: block;
  z-index: 100;
}

.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  // background-color: #f8f8f8;
  align-items: center;
  font-size: 14px;
  color: #6c757d;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 102;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  // box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  .itemLeft {
    display: flex;
    height: 100%;
    font-size: 20px;
    align-items: center;
    margin-left: 14px;
    position: relative;
    cursor: pointer;
    .item {
      display: flex;
      align-items: center;
      margin-left: 6px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.85;
      cursor: pointer;
      .menu {
        font-size: 18px;
        color: #323b47;
        transition: 0.3s;
        opacity: 1;
      }
    }
    .item:hover {
      color: #1876f2;
      background-color: #66d2f925;
      transition: 0.3s;
      opacity: 0.85;
      .menu {
        color: #1876f2;
        transition: 0.3s;
      }
    }
    .filename {
      display: flex;
      margin-left: 16px;
      height: 100%;
      font-size: 14px;
      opacity: 1;
      // color: #323b47;
      align-items: center;
      transition: 0.3s;
      input {
        border: none;
        outline: none;
        color: #1876f2;
        background-color: transparent;
      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
        color: #6c757d;
      }
    }
    .modelshow {
      display: flex;
      margin-left: 16px;
      height: 100%;
      font-size: 12px;
      opacity: 1;
      // color: #6c757d;
      // color: #323b47;
      align-items: center;
      select {
        border: none;
        appearance: none;
        -webkit-appearance: none;
        width: 80px;
        height: 30px;
      }
    }
    .dropdown {
      position: fixed;
      border-radius: 3px;
      font-size: 12px;
      top: 48px;
      left: 310px;
      border: 1px solid #eaeaeabb;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
      z-index: 105;
      ul {
        list-style-type: none;
        padding: 8px 16px;
        li {
          color: #6c757d;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
        }
        li:hover {
          color: #3469e6;
        }
      }
    }
  }
  .logo {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 18px;
    transition: 0.3s;
    img:first-child {
      width: 28px;
      margin-right: 13px;
    }
    img:nth-child(2) {
      height: 24px;
    }
  }
  .nav-right {
    display: flex;
    align-items: center;
    .search {
      width: 260px;
      height: 30px;
      margin-right: 20px;
      align-items: center;
      background-color: #fff;
      display: flex;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      padding: 0 12px;
      .searchicon {
        font-size: 22px;
        color: rgb(127, 127, 127);
        cursor: pointer;
      }
      .searchicon:hover {
        color: #1876f2;
        cursor: pointer;
      }

      input {
        border: none;
        height: 30px;
        padding: 10px;
        width: calc(100% - 56px);
        outline: none;
        color: #4f4f4f;
        background: transparent;

        &::placeholder {
          font-size: 14px;
          color: #d4d3d3;
        }
      }
      .clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border-radius: 24px;
        right: 8px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .clear::before {
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        background: #999;
        transform: rotate(45deg);
      }
      .clear::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        background: #999;
        transform: rotate(315deg);
      }
      .clear:hover {
        background-color: #f8f8f8;
      }
    }

    .items {
      display: flex;
      align-items: center;
      margin-right: 14px;

      .item {
        display: flex;
        align-items: center;
        margin-left: 16px;
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        opacity: 0.85;
        cursor: pointer;
        a {
          text-decoration: none;
          font-size: 14px;
        }
        a:hover {
          color: #1876f2;
        }
        .login {
          margin: 5px;
        }

        .icons {
          font-size: 18px;
        }
        .icons2 {
          font-size: 20px;
          // padding-left: 5px;
        }
        .icons3 {
          font-size: 24px;
          // padding-right: 10px;
        }

        .strangeavatar {
          width: 30px;
          border-radius: 50%;
          background-color: #fff;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 14px;
          height: 14px;
          background-color: #f3376b;
          border-radius: 50%;
          // border: 1px solid #eaeaeabb;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 6px;
          position: absolute;
          top: 2px;
          right: 1px;
        }
        .counter2 {
          width: 14px;
          height: 14px;
          background-color: #19cca3;
          border-radius: 50%;
          // border: 1px solid #eaeaeabb;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 6px;
          // font-weight: bold;
          position: absolute;
          top: 2px;
          right: 1px;
        }
      }
      .item:hover {
        color: #1876f2;
        background-color: #66d2f925;
        transition: 0.3s;
        opacity: 0.85;
      }
      // .item:hover::before {
      //   content: attr(title);
      //   background-color: #333;
      //   color: #fff;
      //   font-size: 12px;
      //   padding: 5px;
      //   border-radius: 3px;
      //   position: absolute;
      //   top: 36px;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
      .dropdown2 {
        position: fixed;
        border-radius: 3px;
        font-size: 12px;
        top: 48px;
        right: 50px;
        border: 1px solid #eaeaeabb;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
        z-index: 105;
        ul {
          list-style-type: none;
          padding: 8px 16px;
          li {
            color: #6c757d;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
          }
          li:hover {
            color: #1876f2;
          }
        }
      }
    }
  }
}

.navbar.hide {
  box-shadow: none;
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0);
}

.navbar.view {
  .menu {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    left: 20px;
  }
  .logo {
    padding-left: 6px;
    transition: 0.3s;
  }
  .filename {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
}
