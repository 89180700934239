.content-left {
  // background-color: #313a46;
  background-color: #4e5468;
  position: fixed;
  color: #9298ad;
  left: -250px;
  top: 60px;
  padding: 25px 20px 20px 20px;
  width: 220px;
  height: calc(100vh - 60px);
  backdrop-filter: blur(25px);
  opacity: 0;
  transition: 0.3s;
  z-index: 1000;
  overflow-y: scroll;
  .leftbar-content {
    width: 100%;
    display: block;
    position: relative;
    flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 20px;
    .lists {
      display: block;
      padding-left: 14px;
      .list-title {
        padding-top: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .title-left {
          display: flex;
          align-items: center;
          img {
            width: 22px;
            margin-right: 14px;
            opacity: 0.25;
          }
        }
        .title-right {
          display: flex;
          align-items: center;
          .list-arrow {
            width: 20px;
            padding-top: 2px;
            padding-right: 3px;
            opacity: 0.25;
            visibility: visible;
          }
          .list-arrow-hidden {
            width: 20px;
            padding-top: 2px;
            padding-right: 3px;
            opacity: 0.25;
            display: none;
          }
        }
      }
      .list-title:hover {
        img {
          opacity: 0.8;
        }
        .list-arrow {
          opacity: 0.8;
        }
        span {
          color: #ffffffbf;
          // font-weight: 600;
        }
      }
      .list-sub {
        visibility: hidden;
        opacity: 0;
        max-height: 0;

        padding-top: 10px;
        padding-left: 26px;
        transition: all 0.3s ease;
      }
    }
    .lists.files {
      padding-top: 12px;
    }
    .lists.active {
      .list-title {
        img {
          opacity: 0.8;
        }
        .list-arrow {
          opacity: 0.8;
        }
        span {
          color: #ffffffbf;
          // font-weight: 600;
        }
      }
      .list-sub {
        visibility: visible;
        opacity: 1;
        max-height: 260px;
        overflow: auto;
        transition: all 0.3s ease;
      }
      .list-sub::-webkit-scrollbar {
        width: 6px; /* 设置滚动条宽度 */
      }
      .list-sub::-webkit-scrollbar-track {
        background-color: transparent; /* 设置滚动条背景色为透明 */
      }

      .list-sub::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1); /* 设置滚动条滑块颜色为半透明 */
        border-radius: 10px; /* 设置滑块边框半径 */
      }
    }
  }
  .backcolor {
    margin-top: 50px;
    .backcoloritems {
      display: flex;
      margin: 15px 15px 15px 15px;
      justify-content: space-between;
      .backcoloritem {
        border: 1px solid #eaeaeabb;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        cursor: pointer;

        .backcoloriteminput {
          // border: 1px solid #eaeaeabb;
          width: 24px;
          height: 24px;
          border-radius: 5px;
          cursor: pointer;
          opacity: 0;
        }
      }
      .backcoloritem:hover {
        border: 2px solid #356ae6;
      }
    }
    .colorinput {
      justify-content: space-between;
      margin-top: 20px;
      .colorhex {
        margin-left: 15px;
        width: 90px;
        height: 24px;
        border: none;
        padding: 5px;
        color: #393c48;
        opacity: 0.8;
        border-radius: 2px;
      }
      .colorupdate {
        margin-left: 3px;
        width: 50px;
        height: 24px;
        color: #ffffff;
        font-size: 12px;
        border: none;
        border-radius: 2px;
        background-color: #1b54d9;
        cursor: pointer;
      }
      .colorupdate:hover {
        background-color: #356ae6;
      }
    }
  }
  .backpic {
    margin-top: 30px;
    width: 170px;
    .backpicitems {
      display: flex;
      margin-top: 12px;
      width: 100%;
      height: 100px;
      background-color: rgba(255, 255, 255, 0.2);
      // justify-content: space-between;
      img {
        width: 100%;
      }
    }
    .picinput {
      justify-content: space-between;
      margin-top: 20px;
      .pichex {
        // margin-left: 15px;
        margin-bottom: 10px;
        width: 100%;
        height: 24px;
        border: none;
        padding: 5px;
        color: #393c48;
        border-radius: 2px;
        opacity: 0.8;
        // pointer-events: none;
      }
	  .piclabel {
        margin-left: 3px;
        width: 100%;
        color: #ffffff;
        font-size: 13px;
        opacity: 0.55;
        cursor: pointer;
      }
      .piclabel:hover {
        opacity: 0.65;
        text-decoration: underline;
        // font-weight: bold;
      }
      .picfile {
        margin-left: 3px;
        width: 100%;
        color: #ffffff;
        font-size: 12px;
        opacity: 0.45;
		display: none;
      }
      .picbuttons {
        justify-content: space-between;
        .picupdate,
        .picdisable,
        .picdelete {
          margin-top: 15px;
          width: 50px;
          height: 24px;
          color: #ffffff;
          font-size: 12px;
          border: none;
          border-radius: 2px;
          background-color: #1b54d9;
          cursor: pointer;
        }
        .picupdate,
        .picdisable {
          margin-right: 10px;
        }
        .picdisable {
          background-color: #d9b61b;
        }
        .picdelete {
          background-color: #d91b51;
        }
        .picupdate:hover {
          background-color: #356ae6;
        }
        .picdisable:hover {
          background-color: #efc91f;
        }
        .picdelete:hover {
          background-color: #ef225d;
        }
      }
    }
  }
  .leftbar-img {
    width: 50px;
    height: 50px;
    align-items: center;
    padding: 50px;
    margin-left: 30px;
    opacity: 0.65;
  }
}

// .content-left::-webkit-scrollbar {
//   width: 6px;
// }
// .content-left::-webkit-scrollbar-track {
//   // background: #f1f1f1;
//   background-color: transparent;
// }
// .content-left::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.1);  /* 设置滚动条滑块颜色为半透明 */
//         border-radius: 10px;
// }
// .content-left::-webkit-scrollbar-thumb:hover {
//   background: rgba(204, 204, 204, 0.38);
// }

//show
.content-left.show {
  left: 0px;
  opacity: 1;
  transition: 0.3s;
}
.content-left.show::-webkit-scrollbar {
  width: 6px;
}
.content-left.show::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background-color: transparent;
}
.content-left.show::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px;
}
