.popuplayout-chat {
  position: absolute;
  box-sizing: border-box;
  z-index: 107;
  padding: 0;
  min-width: 0;
  font-size: 14px;
  top: 74px;
  right: 100px;
  width: 280px;
  // height: 326px;
  max-height: 326px;
  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  background-color: #fff;
  overflow: hidden;
  //   box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  box-shadow: 0px 3px 8px 4px rgb(17 71 162 / 3%),
    0px 3px 8px 4px rgb(17 71 162 / 3%);

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  .popupbody.chat {
    align-items: center;
    width: 100%;
    position: relative;
    padding: 8px 0 8px;
    .chat-title {
      display: flex;
      height: 40px;
      padding: 10px 20px;
      border-bottom: 1px solid #eaeaeabb;
      justify-content: space-between;

      .title-name {
        font-weight: 500;
        letter-spacing: 1px;
        color: #6c757d;
      }
      .clear {
        color: #6c757d;
        font-size: 13px;
        cursor: pointer;
        border-radius: 10px;
      }
      .clear:hover {
        color: #1876f2;
      }
    }
    .chat-lists {
      // height: 234px;
      max-height: 234px;
      overflow-y: scroll;
      .lists-content {
        display: block;
        position: relative;
        flex-wrap: wrap;
        .chat-list {
          display: flex;
          height: 60px;
          .list-left {
            display: flex;
            height: 100%;
            width: 70px;
            align-items: center;
            justify-content: center;
          }
          .list-left img {
            display: flex;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            // opacity: 0.2;
          }
          .list-right {
            .right-up {
              display: flex;
              height: 35px;
              max-width: 198px;
              // background-color: rgb(246, 152, 152);
              color: #6c757d;
              font-size: 13px;
              align-items: flex-end;
              word-wrap: break-word;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .right-down {
              display: flex;
              height: 40px;
              color: #6c757d72;
              font-size: 11px;
            }
          }
        }
        .chat-list:hover {
          background-color: #f1f5fe9c;
          .list-right {
            .right-up {
              color: #1876f2;
            }
          }
        }
      }
    }
    .chat-footer {
      display: flex;
      font-size: 13px;
      border-top: 1px solid #eaeaeabb;
      height: 42px;
      color: #6c757d;
      align-items: center;
      justify-content: center;
    }
    .chat-footer:hover {
      span {
        color: #1876f2;
        cursor: pointer;
      }
    }
  }
}

.chat-lists::-webkit-scrollbar {
  width: 6px;
}
.chat-lists::-webkit-scrollbar-track {
  // background: #f1f1f1;
  border-radius: 25px;
}
.chat-lists::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 25px;
  border: 0.5rem solid transparent;
}
.chat-lists::-webkit-scrollbar-thumb:hover {
  background: rgba(204, 204, 204, 0.38);
}

.popuplayout-chat.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-15px);
  transition: 0.2s;
  z-index: 107;
}
