* {
  margin: 0;
  padding: 0;
}
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Microsoft YaHei", 微软雅黑;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.box {
  width: 4.5rem;
  height: 8.35rem;
  background: url(images/bg.jpg) center no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}
.lottery {
  width: 4rem;
  height: 4rem;
  background: url(images/box.png) center no-repeat;
  background-size: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -1.85rem;
  margin-left: -2rem;
}
.block {
  position: absolute;
  width: 2rem;
  height: 2rem;
  transform-origin: right bottom 0;
}

.block:nth-child(1) {
  transform: rotate(-20deg) skewY(50deg);
}
.block:nth-child(2) {
  transform: rotate(20deg) skewY(50deg);
}
.block:nth-child(3) {
  transform: rotate(60deg) skewY(50deg);
}
.block:nth-child(4) {
  transform: rotate(100deg) skewY(50deg);
}
.block:nth-child(5) {
  transform: rotate(140deg) skewY(50deg);
}
.block:nth-child(6) {
  transform: rotate(180deg) skewY(50deg);
}
.block:nth-child(7) {
  transform: rotate(220deg) skewY(50deg);
}
.block:nth-child(8) {
  transform: rotate(260deg) skewY(50deg);
}
.block:nth-child(9) {
  transform: rotate(300deg) skewY(50deg);
}

.btn {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  background: url(images/z.png) center no-repeat;
  background-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.55rem;
  margin-left: -0.7rem;
  cursor: pointer;
  color: #fff;
}

.tanchuang {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.5); /* 初始缩放为0 */
  color: #fff;
  z-index: 999;
  background-size: 100% 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear, transform 0.5s;
  display: flex; /* 使用 flexbox 进行居中对齐 */
  justify-content: center;
  align-items: center; /* 垂直居中 */
}

.tanchuang.show {
  visibility: visible;
  opacity: 1;
  //   animation: popup 0.5s ease;
  animation: scaleIn 0.5s forwards; /* 应用动画 */
  //   transform: translate(-50%, -50%) scale(1); /* 变为正常大小 */
  //   transition: visibility 0s, opacity 0.5s linear, transform 0.5s; /* 确保过渡顺畅 */
}

.tanchuang img {
  width: 100%; /* 限制图片宽度 */
  height: 100%; /* 限制图片高度 */
  //   object-fit: contain; /* 使图片等比例缩放 */
}

@keyframes scaleIn {
  0% {
    transform: translate(-50%, -50%) scale(1.5); /* 初始状态 */
    opacity: 0; /* 透明度 */
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* 最终状态 */
    opacity: 1; /* 不透明 */
  }
}

.tanchuang.exit {
  animation: scaleOut 0.5s forwards; /* 退出动画 */
}

@keyframes scaleOut {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
