.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9ca2b50b; /* 半透明黑色背景 */
  // background-color: rgba(0, 0, 0, 0.025); /* 半透明黑色背景 */
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  // display: none;
  z-index: 100;
}
.overlay.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
  // display: block;
  z-index: 100;
}

.popuplayoutover {
  @media (max-width: 650px) {
    top: 65px;
    left: calc(50% - 160px);
    transform: translateX(-50%);
  }
  display: flex;
  position: absolute;
  top: 15px;
  right: 85px;
  width: 320px;
  // height: 480px;
  background: none;
  z-index: 114;
  opacity: 0;
  visibility: hidden;
  // opacity: 0;
  // visibility: hidden;
  transform: translateY(0px);
  transform-origin: 100% 0 0 scale(0.8);
  transition: 0.3s ease;

  .popupclear {
    display: flex;
    .popupuseravatar {
      position: absolute;
      left: 140px;
      z-index: 115;
      align-items: center;
      cursor: pointer;
      .strangeavatar {
        width: 50px;
        border-radius: 50%;
      }
    }
  }
  .popuplayout {
    position: relative;
    top: 25px;
    left: 0;
    right: 116px;
    width: 100%;
    // height: 470px;
    max-height: 480px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    // box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 8px 4px rgb(17 71 162 / 3%),
      0px 3px 8px 4px rgb(17 71 162 / 3%);
    border-radius: 4px;
    // padding: 5px 0px;
    font-size: 14px;
    overflow: auto;

    .popupbody {
      top: 15px;
      padding-bottom: 10px;
      //  xx
      display: block;
      .popupitems {
        border-bottom: 1px solid #eaeaeabb;
        color: #6c757d;
        width: 100%;
        margin: 6px 0;
        .popupitem {
          font-size: 13px;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }

    .filemanage {
      display: block;
      .filelayout {
        .returnbtn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          margin-top: -40px;
          margin-bottom: 20px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #e5ebf349;
          cursor: pointer;

          .returnicon {
            margin-left: 5px;
            width: 18px;
          }
        }
        .returnbtn:hover {
          background-color: #66d2f925;
        }
        .filetitle {
          display: flex;
          align-items: center;
          color: #356ae6;
          font-size: 13px;
          justify-content: center;
          margin-left: 220px;
          margin-top: -46px;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .filetitle:hover {
          text-decoration: underline;
        }
        .popupitems {
          border-top: 1px solid #eaeaeabb;
          color: #6c757d;
          width: 100%;
          margin: 6px 0;
          .popupitem {
            font-size: 13px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 15px;
          }
        }
        .filelist {
          // background-color: rgb(154, 204, 247);
          margin: -5px 20px 5px 30px;
          font-size: 13px;
          max-height: 160px;
          overflow: auto;
          .fileitem {
            display: flex;
            position: relative;
            justify-content: space-between;
            height: 36px;
            border-radius: 3px;
            align-items: center;
            color: #6c757d;
            .fileinfo {
              display: flex;
              .fileindex {
                display: flex;
                align-items: center;
                margin-left: 8px;
                color: #bdc2d4;
              }
              .filenames {
                display: flex;
                margin-left: 10px;
                line-height: 30px;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                // background-color: #356ae6;
                text-overflow: ellipsis;
                cursor: pointer;
              }
            }
            .morebtn:hover ~ .fileinfo .filenames {
              background-color: transparent;
              color: inherit;
            }
            .morebtn {
              display: flex;
              width: 28px;
              height: 28px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              opacity: 0;
              cursor: pointer;
              .moreicon {
                width: 22px;
              }
            }
            .morebtn:hover {
              border: 2px solid #eaeaeabb;
              // background-color: #66d2f925;
            }
            .morebtn.active {
              opacity: 0.8;
            }
            .filedropdown {
              position: absolute;
              top: 25px;
              right: 12px;
              border-radius: 3px;
              font-size: 12px;
              border: 1px solid #eaeaeabb;
              background: #f1f5fe9c;
              box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
              z-index: 105;
              ul {
                list-style-type: none;
                padding: 8px 16px;
                li {
                  color: #6c757d;
                  height: 30px;
                  line-height: 30px;
                }
                li:hover {
                  color: #3469e6;
                  text-decoration: underline;
                }
              }
            }
          }
          .fileitem:hover {
            background-color: #f1f5fe9c;
            color: #3469e6;
          }
        }
        .filelist::-webkit-scrollbar {
          width: 4px; /* 设置滚动条宽度 */
        }
        .filelist::-webkit-scrollbar-track {
          background-color: transparent; /* 设置滚动条背景色为透明 */
        }

        .filelist::-webkit-scrollbar-thumb {
          background-color: rgba(
            0,
            0,
            0,
            0.03
          ); /* 设置滚动条滑块颜色为半透明 */
          border-radius: 10px; /* 设置滑块边框半径 */
        }
        .space {
          height: 20px;
        }
      }
      .filelayout.files {
        margin-top: 60px;
      }
      .filelayout.keeps,
      .filelayout.likes {
        margin-top: 18px;
      }
    }
  }
  .popupitem {
    justify-content: space-between;
    .popupuseravatar {
      position: absolute;
      top: -10px;
    }
    .popuppercent100 {
      width: 100%;
      .popupiconlist {
        display: flex;
        height: 45px;
        align-items: center;
        // padding: 15px;
        margin: 0 30px;
        // margin-right: 26px;
        justify-content: space-between;
        .user-coin {
          display: flex;
          height: 38px;
          text-align: left;
          align-items: center;
          .popupicons {
            display: flex;
            margin-right: 18px;
            span {
              margin-left: 6px;
            }
            .numbers.vips {
              color: #f3ba00;
            }
            .numbers.pps {
              color: #356ae6;
            }
            .icons3 {
              font-size: 15px;
            }
          }
        }
        .user-com {
          display: flex;
          text-align: right;
          align-items: center;
          opacity: 0.8;
          .popupicons {
            display: flex;
            margin-left: 22px;
            font-size: 16px;
            cursor: pointer;
            .icons3:hover {
              color: #3469e6;
            }
          }
        }
      }
      .user-name {
        display: flex;
        margin-top: 10px;
        height: 80px;
        align-items: center;
        justify-content: center;
        span {
          color: #356ae6;
          font-size: 16px;
          margin-right: 10px;
        }
        .popupicons:hover {
          color: #3469e6;
          cursor: pointer;
        }
      }
      .user-center,
      .user-file-admin,
      .user-order,
      .user-business,
      .user-quit {
        display: flex;
        width: 100%;
        height: 30px;
        // margin: 0 15px;
        // margin-left: 18px;
        margin-bottom: 7px;
        align-items: center;
        cursor: pointer;
        .popupicons {
          display: flex;
          align-items: center;
          margin: 14px;
          .icons3 {
            margin-left: 18px;
          }
        }
      }
      .user-quit {
        margin-top: 13px;
        font-size: 13px;
        color: #6c757d;
      }
      .user-center:hover,
      .user-file-admin:hover,
      .user-order:hover,
      .user-business:hover {
        color: #3469e6;
        background-color: #66d2f912;
        transition: 0.3s;
      }
      .user-quit:hover {
        color: #f3376b;
      }
    }
    .popuppercent50 {
      width: 50%;
      .user-vip {
        display: flex;
        height: 35px;
        text-align: left;
        margin-left: 30px;
        color: #ff8730;
        cursor: pointer;
        .files {
          .fileskeepicon,
          .filesusericon,
          .fileslikeicon {
            // display: flex;
            width: 18px;
            opacity: 0.8;
            margin-top: 1px;
            margin-right: 10px;
            opacity: 0.7;
          }
        }
      }
      .user-vip.files {
        color: #3469e6;
        span {
          opacity: 0.85;
        }
        .listname {
          cursor: pointer;
        }
        .listname:hover {
          text-decoration: underline;
        }
        .userinfo {
          margin-left: 10px;
        }
      }
      .user-vipup {
        margin-right: 30px;
        text-align: right;
        color: #3469e6;
        cursor: pointer;
      }
      .user-vipup.files {
        color: #ff8730;
      }
      .user-vip:hover,
      .user-vipup:hover {
        // color: #1876f2;
        text-decoration: underline;
      }
      .user-vip.files:hover,
      .user-vipup.files:hover {
        text-decoration: none;
        cursor: default;
      }
    }
    .popuppercent80 {
      width: 88%;
      .user-vip {
        display: flex;
        height: 30px;
        text-align: left;
        margin-left: 30px;
        cursor: pointer;
      }
      .user-vip.files {
        color: #3469e6;
        span {
          opacity: 0.85;
        }
        .infotitle {
          color: #6c757d;
          width: 70px;
          margin-right: 10px;
        }
        .userinfo {
          // margin-left: 10px;
          input {
            border: none;
            font-size: 13px;
            opacity: 0.6;
          }
          .error-input {
            color: #ef225d;
          }
        }
        .userinfo.desc {
          // margin-left: 40px;
          textarea {
            // border: 1px solid #cacaca;
            border: none;
            font-size: 13px;
            resize: none;
            // background: #eaeaea82;
            border: 0.5px solid #dedede;
            opacity: 0.6;
          }
        }
        .userbtn {
          margin-left: 30px;
          input {
            display: none;
          }
        }
        .userbtn:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .user-vip:hover {
        // color: #1876f2;
        text-decoration: underline;
      }
      .user-vip.files:hover {
        text-decoration: none;
        cursor: default;
      }
      .user-vip.files.icon {
        margin-top: 20px;
        .backpicitems {
          display: flex;
          margin-left: 30px;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: #eaeaea82;
          img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }
      .userbuttons {
        justify-content: space-between;
        margin-bottom: 20px;
        .userupdate,
        .userdelete {
          margin-top: 32px;
          width: 50px;
          height: 24px;
          color: #ffffff;
          font-size: 12px;
          border: none;
          border-radius: 2px;
          background-color: #1b54d9;
          cursor: pointer;
        }
        .userupdate {
          margin-left: 80px;
        }
        .userdelete {
          margin-left: 60px;
          background-color: #d91b51;
        }
        .userupdate:hover {
          background-color: #356ae6;
        }
        .userdelete:hover {
          background-color: #ef225d;
        }
      }
    }
    .popuppercent25 {
      width: 25%;
      .user-files,
      .user-fans,
      .user-saves,
      .user-trends {
        display: flex;
        flex-wrap: wrap;
        height: 45px;
        text-align: center;
        margin: 8px 0;
        cursor: pointer;
        // align-items: center;
        // justify-content: center;
        span {
          width: 100%;
        }
        .numbers {
          font-size: 14px;
        }

        .numbers.files {
          color: #356ae6;
        }
        .numbers.fans {
          color: #66c9f9;
        }
        .numbers.saves {
          color: #19cca3;
        }
        .numbers.trends {
          color: #7733ff;
        }
        .numbers.files:hover,
        .numbers.fans:hover,
        .numbers.saves:hover,
        .numbers.trends:hover {
          color: #1b54d9;
        }
      }
      .user-files:hover,
      .user-fans:hover,
      .user-saves:hover,
      .user-trends:hover {
        color: #1876f2;
      }
    }
  }
}

.popuplayoutover.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transform-origin: 100% 0 0 scale(1);
  transition: 0.3s ease;
}
.popuplayout::-webkit-scrollbar {
  display: none;
}
