.editinfocard {
  width: 210px;
  max-height: 700px;
  background: rgba(255, 255, 255, 0.88);
  position: fixed;
  top: 90px;
  left: 20px;
  //   background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  // box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 15px;
  min-width: 0;
  font-size: 14px;

  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  .edithead {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #eaeaeabb;
    .draggabletitle {
      font-size: 14px;
      color: #393c48;
      padding: 5px;
      opacity: 0.8;
      cursor: pointer;
    }
    .draggabletitle:hover {
      color: #393c48;
      opacity: 1;
    }
    .closeicon {
      opacity: 0.4;
      width: 18px;
      cursor: pointer;
    }
    .closeicon:hover {
      opacity: 0.8;
    }
  }
  .nodecentent {
    max-height: 120px;
    overflow: auto;
    margin: 5px;
    width: 100%;
	.editable-input {
      width: 100%;
      font-size: 12px;
      color: #393c48;
      border: none;
      resize: none;
      // resize: vertical;
      word-wrap: break-word;
      overflow-wrap: break-word;
      height: auto;
      min-height: 60px;
      // overflow: hidden;
      overflow: auto;
    }
    .nodedesc {
      font-size: 12px;
      color: #393c48;
      // border-bottom: 0.5px solid #eaeaeabb;
      padding: 5px;
    }
  }
  .nodestyleitems {
    max-height: 400px;
    overflow: auto;
    margin: 5px;
    width: 100%;
    .styleitem {
      width: 100%;
      font-size: 13px;
      .styletitle {
        color: #1da1f2;
        height: 38px;
        font-size: 12px;
      }
      .itemsub {
        display: flex;
        height: 30px;
        justify-content: space-between;
        margin: 8px 5px 8px 0px;
        color: #aab8c2;
        .subtitle {
          display: flex;
          width: 25%;
          font-size: 12px;
          align-items: center;
         //margin-right: 2px; //---xx
        }
        .lockicon {
          display: flex;
          align-items: center;
          width: 16px;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
        }
        .subitem {
          display: flex;
          width: 70%;
          position: relative;
          align-items: center;

          input {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            font-size: 12px;
            border: none;
            color: #393c48;
            background: rgba(255, 255, 255, 0);
            border-bottom: 0.5px solid #9ca2b598;
            padding-left: 1px;
            text-align: center;
            cursor: pointer;
            // position: relative;
          }
          .linethickness {
            margin-left: 18px;
            text-align: center;
          }
          .stypeicon {
            width: 20px;
            margin-left: 0px;
            opacity: 0.8;
            cursor: pointer;
          }
          .stypeicon3 {
            width: 20px;
            margin-left: 15px;
            opacity: 0.8;
            cursor: pointer;
          }
          .stypeicon1 {
            width: 20px;
            margin-left: 3px;
            opacity: 0.8;
            cursor: pointer;
          }
          .stypeicon11 {
            width: 20px;
            margin-left: 16px;
            opacity: 0.65;
          }
          .itemalign {
            display: flex;
            margin-left: 2px;
            .stypeicon2 {
              width: 15px;
              margin-left: 8px;
              opacity: 0.95;
              cursor: pointer;
            }
          }
          .nodetypeoptions {
            position: absolute; /* 添加绝对定位 */
            top: 100%; /* 将顶部位置设置为输入框底部 */
            left: -50px;
            margin-top: 3px;
            padding: 8px 0;
            width: 170px;
            border-radius: 3px;
            border: 0.5px solid #9ca2b598;
            background-color: #fff;
            box-shadow: 0px 3px 8px 4px rgb(17 71 162 / 3%),
              0px 3px 8px 4px rgb(17 71 162 / 3%);
            cursor: pointer;
            .nodetypeoption {
              color: #939da5;
              font-size: 13px;
              margin: 2px 0;
              padding-left: 10px;
            }
            .nodetypeoption.selected {
              color: #3469e6;
            }
            .nodetypeoption:hover {
              color: #3469e6;
            }
          }
          .fontoptions {
            position: absolute; /* 添加绝对定位 */
            top: 100%; /* 将顶部位置设置为输入框底部 */
            left: -50px;
            margin-top: 3px;
            padding: 8px 0;
            width: 170px;
            border-radius: 3px;
            border: 0.5px solid #9ca2b598;
            background-color: #fff;
            box-shadow: 0px 3px 8px 4px rgb(17 71 162 / 3%),
              0px 3px 8px 4px rgb(17 71 162 / 3%);
            cursor: pointer;
            .fontoption {
              color: #939da5;
              font-size: 13px;
              margin: 2px 0;
              padding-left: 10px;
            }
            .fontoption.selected {
              color: #3469e6;
            }
            .fontoption:hover {
              color: #3469e6;
            }
          }
        }

        .subdes {
          display: flex;
          align-items: center;
          width: 10%;
        }
        .subcolor {
          display: flex;
          align-items: center;
          margin-top: 5px;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 0.5px solid #9ca2b598;
          background-color: #ffffff;
          cursor: pointer;
        }
      }
    }
    .propertyinput {
      .propertybuttons {
        justify-content: space-between;
        .propertyupdate,
        .propertycancel {
          margin-top: 15px;
          margin-bottom: 20px;
          width: 48px;
          height: 24px;
          color: #ffffff;
          font-size: 11px;
          border: none;
          border-radius: 2px;
          background-color: #1b54d9;
          cursor: pointer;
        }
        .propertyupdate,
        .propertycancel {
          margin-right: 20px;
          margin-left: 10px;
        }
        .propertyupdate {
          background-color: #1b54d9;
        }

        .propertycancel {
          background-color: #d9b61b;
        }
        .propertyupdate:hover {
          background-color: #356ae6;
        }

        .propertycancel:hover {
          background-color: #efc91f;
        }
      }
    }
    .backpic {
      margin-top: 15px;
      width: 160px;
      label {
        color: #1da1f2;
        font-size: 13px;
      }
      .picsubtitle {
        margin-top: 14px;
        font-size: 12px;
        align-items: center;
        color: #aab8c2;
      }
      .uploadedpics {
        display: flex;
        margin-top: 12px;
        width: 100%;
        max-height: 130px;
        overflow-y: auto;
        border: 0.5px solid #9ca2b550;
        .nodepiclayout {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 10px;
          padding: 10px;
          .nodepicitems {
            // flex: 1;
            width: calc(50% - 10px);
            overflow: hidden;
            position: relative;
            .nodepicitem {
              width: 100%;
              border-radius: 5px;
              height: 40px;
              display: block;
              cursor: pointer;
            }
            .nodepicitem:hover {
              border: 1px solid #1da1f2;
            }
            .nodepicitem.selected {
              border: 2px solid #1da1f2;
            }
            .nodepicname {
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: #393c48;
              font-size: 8px;
              color: #aab8c2;
              max-width: 99%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
              // padding: 0 3px;
            }
            .nodepicname:hover {
              color: #1da1f2;
            }
          }
        }
      }
      .backpicitems {
        display: flex;
        margin-top: 12px;
        width: 100%;
        height: 80px;
        background-color: #f1f5fe;
        // justify-content: space-between;
        img {
          width: 100%;
        }
      }
      .picinput {
        justify-content: space-between;
        margin-top: 20px;
        .pichex {
          // margin-left: 15px;
          margin-bottom: 10px;
          width: 100%;
          height: 1px;
          border: none;
          padding: 5px;
          color: #393c48;
          background-color: #f1f5fe;
          border-radius: 2px;
          opacity: 0.8;
          display: none; //hidden input address
          // pointer-events: none;
        }
        .piclabel {
          font-size: 12px;
          color: #356ae6;
          opacity: 0.8;
          margin-left: 3px;
          cursor: pointer;
        }
        .piclabel:hover {
          opacity: 1;
          text-decoration: underline;
        }
        .picfile {
          margin-left: 3px;
          width: 100%;
          color: #4b4bf7;
          font-size: 12px;
          opacity: 0.45;
          display: none;
        }
        .picbuttons {
          justify-content: space-between;
          .picupdate,
          .picdisable,
          .picdelete {
            margin-top: 15px;
            margin-bottom: 20px;
            width: 48px;
            height: 24px;
            color: #ffffff;
            font-size: 11px;
            border: none;
            border-radius: 2px;
            background-color: #1b54d9;
            cursor: pointer;
          }
          .picupdate,
          .picdisable {
            margin-right: 5px;
          }
          .picdisable {
            background-color: #d9b61b;
          }
          .picdelete {
            background-color: #d91b51;
          }
          .picupdate:hover {
            background-color: #356ae6;
          }
          .picdisable:hover {
            background-color: #efc91f;
          }
          .picdelete:hover {
            background-color: #ef225d;
          }
        }
      }
    }
  }
  .infobutton {
    .infoclose {
      position: absolute;
      top: 18px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      right: 12px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .infoclose::before {
      position: absolute;
      content: "";
      width: 1px;
      height: 12px;
      background: rgb(188, 188, 188);
      transform: rotate(45deg);
    }
    .infoclose::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 12px;
      background: rgb(188, 188, 188);
      transform: rotate(315deg);
    }
    // .infoclose:hover {
    //   background-color: #fe7d7d;
    // }
    .infoclose:hover::before {
      background: #fe7d7d;
    }
    .infoclose:hover::after {
      background: #fe7d7d;
    }
  }
  .infoitems {
    .nodedesc {
      padding: 5px;
      font-size: 12px;
      color: #6c757d;
    }
    .infoshow {
      max-height: 150px;
      overflow: auto;
      margin: 2px 0 6px 0;
      transition: all 0.3s ease;
      .nodetitles .titlesshow {
        width: max-content;
        border: none;
        color: #1876f2;
        font-size: 12px;
        // border-bottom: 0.5px solid #1876f2;
        margin: 4px 0;
      }
      .nodedetails .detailsshow {
        border: none;
        margin: 5px 0;
      }
    }

    .nodeinfo {
      max-height: 150px;
      overflow: auto;
      margin: 2px 0 6px 0;
      transition: all 0.3s ease;
      .nodetitles .titlesinput {
        width: max-content;
        border: none;
        color: #1876f2;
        font-size: 12px;
        // border-bottom: 0.5px solid #1876f2;
        margin: 4px 0;
      }
      .nodedetails .detailsinput {
        border: none;
        margin: 5px 0;
      }
      .button-group {
        display: flex;
        justify-content: center;
        .infoaddbutton,
        .infosavebutton {
          width: 40%;
          height: 28px;
          border: none;
          margin: 10px;
          border-radius: 3px;
          color: #6c757d;
          font-size: 12px;
        }
        .infoaddbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #3b35e6ab;
        }

        .infosavebutton:hover {
          color: rgb(255, 255, 255);
          background-color: #2261f3ab;
        }
      }
    }

    .nodeimage {
      label {
        color: #356ae6;
        // color: #393c48;
      }
    }

    .nodeaiinfo {
      margin-top: 12px;
      label {
        color: #356ae6;
        // color: #393c48;
      }
      .aipic {
        display: flex;
        margin-left: 150px;
        margin-top: -18px;
        // width: 30px;
        // height: 30px;
        background-color: transparent;
        // align-items: center;
        // justify-content: center;
        border-radius: 50%;
        transition: 0.3s;
        opacity: 0.45;
        img {
          width: 18px;
          height: 18px;
          align-self: center;
        }
      }
      .aipic:hover {
        // background-color: #66d2f925;
        transition: 0.3s;
        opacity: 0.8;
      }
      input {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    .username {
      font-size: 12px;
      color: #6c757d;
    }

    .date {
      color: #6c757d;
      font-size: 12px;
    }
    span {
      padding-top: 10px;
      padding-right: 10px;
    }
    .aiinput {
      margin-top: 6px;
      border: none;
      border-bottom: 0.5px solid #eaeaeabb;
    }

    .speak-bottom {
      display: flex;
      height: 50px;
      // margin-top: 13px;
      // align-items: center;
      // justify-content: space-between;
      .bottom-left {
        display: flex;
        // display: inline-flex;
        // padding-left: 0px;
        align-items: center;
        .speakicons {
          color: #1da1f2;
          font-size: 14px;
          // margin: 0 2px;
          height: 26px;
          width: 26px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: background 0.2s ease;
          .icons4 {
            font-size: 12px;
          }
        }
        .speakicons:hover {
          background: #e7f5fe;
        }
        .number {
          display: flex;
          font-size: 12px;
          padding-top: 4px;
          color: #6c757d;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        .counter {
          color: #333;
          display: none;
          font-weight: 500;
          margin-right: 15px;
          padding-right: 15px;
          border-right: 1px solid #aab8c2;
        }
        button {
          margin-right: 5px;
          padding: 4px 16px;
          border: none;
          outline: none;
          border-radius: 50px;
          font-size: 12px;
          // font-weight: 600;
          background: #1da1f2;
          color: #fff;
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none;
          transition: background 0.2s ease;
        }
        button.active {
          opacity: 1;
          pointer-events: auto;
        }
        button:hover {
          background: #0d8bd9;
        }
      }
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 100%;

    .morebtn {
      margin-top: 10px;
      cursor: pointer;
      opacity: 0.7;
    }
    .morebtn:hover {
      opacity: 1;
    }
  }
}

.nodecentent::-webkit-scrollbar {
  width: 4px; /* 设置滚动条宽度 */
}
.nodecentent::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.nodecentent::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.editable-input::-webkit-scrollbar {
  width: 4px; /* 设置滚动条宽度 */
}
.editable-input::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.editable-input::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.nodestyleitems::-webkit-scrollbar {
  width: 4px; /* 设置滚动条宽度 */
}
.nodestyleitems::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.nodestyleitems::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.editinfocard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.1s;
  z-index: 901;
}

.colorPickerContainer {
  position: fixed;
  // top: 280px;
  // left: 220px;
  z-index: 1000; /* 设置一个较高的z-index值 */
}
.fillcolorPickerContainer {
  position: fixed;
  // top: 360px;
  // left: 220px;
  z-index: 1000; /* 设置一个较高的z-index值 */
}
.strokecolorPickerContainer {
  position: fixed;
  // top: 380px;
  // left: 220px;
  z-index: 1000; /* 设置一个较高的z-index值 */
}
.linkStrokeColorPickerContainer {
  position: fixed;
  // top: 380px;
  // left: 220px;
  z-index: 1000; /* 设置一个较高的z-index值 */
}
