.datatable {
    position: fixed;
    font-size: 8px;
    top: 60px;
    width: 100%;
   
    .icon-table {
      display: flex;
      cursor: pointer;
      width: 36px;
      height: 36px;
      margin-right: 8px;
      float: left;
      border-radius: 50%;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.35;
      img {
        width: 20px;
        height: 20px;
        opacity: 0.85;
      }
    }
    .icon-table:hover {
      background-color: #66d2f944;
      transition: 0.3s;
      opacity: 0.7;
      img {
          opacity: 0.75;
        }
    }
    .icon-table.edit:hover {
      background-color: #3cfe2e44;
   
    }
    .icon-table.delete:hover {
      background-color: #f9666644;   
    }
  
    .icon-table-deleted {
      display: flex;
      // cursor: pointer;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      float: left;
      border-radius: 50%;
      background-color: #00cd90;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.65;   
    }
    .icon-table-deleted.active {    
      background-color: #ec0404;
      
    }
  
  }
  
  
  