.groupcard {
  width: 210px;
  max-height: 700px;
  background: #fff;
  position: fixed;
  top: 90px;
  left: 20px;
  //   background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  // box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  //   backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 15px;
  min-width: 0;
  font-size: 14px;

  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  .infohead {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #eaeaeabb;
    .infodraggabletitle {
      font-size: 14px;
      color: #393c48;
      padding: 5px;
      opacity: 0.8;
      cursor: pointer;
    }
    .infodraggabletitle:hover {
      color: #393c48;
      opacity: 1;
    }
    .closeicon {
      opacity: 0.4;
      width: 18px;
      cursor: pointer;
    }
    .closeicon:hover {
      opacity: 0.8;
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 100%;
    // cursor: pointer;
    .morebtn {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .groupitems {
    .creategroup,
    .groupupdate {
      margin-top: 10px;
      label {
        color: #356ae6;
        font-size: 12px;
        // color: #393c48;
      }
      .groupinput {
        font-size: 13px;
        color: #6c757d;
        margin: 10px 0 2px 0;
        padding: 5px 1px;
        resize: none;
        border: none;
        opacity: 0.85;
        border-bottom: 0.5px solid #eaeaeabb;
      }
      .groupinput::placeholder {
        color: #6c757d6a;
        font-size: 12px;
      }
      .button-group {
        display: flex;
        justify-content: center;
        .inforenewbutton,
        .infocancelbutton,
        .infoclearbutton {
          width: 30%;
          height: 26px;
          border: none;
          margin: 10px;
          border-radius: 3px;
          color: #6c757d;
          font-size: 12px;
          cursor: pointer;
        }
        .inforenewbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #2261f3ab;
        }

        .infocancelbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #f3376b;
        }
        .infoclearbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #f3c437;
        }
      }
    }
    .groupitems {
      .grouptitle {
        color: #356ae6;
        font-size: 12px;
        margin-top: 12px;
      }
      .userfiletitle {
        color: #6c757da9;
        font-size: 12px;
        margin-top: 5px;
        .filesnum {
          float: right;
          margin-right: 10px;
          font-size: 10px;
          color: #1897f2c7;
        }
      }

      .custom-dropdown {
        position: relative;

        .custom-dropdown-toggle {
          cursor: pointer;
          margin-top: 10px;
          padding: 8px;
          border: 0.5px solid #eaeaeabb;
          //   border-bottom: 0.5px solid #eaeaeabb;
          color: #1897f2c7;
          font-size: 13px;
          border-radius: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .custom-dropdown-toggle:hover {
          // box-shadow: 0 2px 10px rgba(183, 183, 183, 0.25);
          box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
          color: #1897f2;
        }
        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: #fff;
          max-height: 150px;
          overflow-y: auto;
          z-index: 905;
          .custom-dropdown-options {
            width: 100%;
            height: 100%;
            color: #6c757dda;
            font-size: 12px;
            background-color: #fff;
            border: 0.5px solid #eaeaeabb;
            border-top: none;
            display: flex;
            flex-direction: column;
          }

          .custom-dropdown-option {
            padding: 6px;
            height: 30px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              // background-color: #f0f0f0;
              color: #356ae6;
            }
            &.selected {
              background-color: #1897f2c7;
              color: #fff;
              &:hover {
                background-color: #1897f2c7;
              }
            }
          }
        }
        .dropdown-menu:hover {
          // box-shadow: 0 2px 10px rgba(183, 183, 183, 0.25);
          box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
        }
      }
      .userfilelist,
      .groupcontent,
      .selectcontent {
        margin-top: 12px;
        // background-color: rgb(213, 205, 205);
        width: 100%;
        max-height: 100px;
        overflow-y: auto;
        z-index: 902;
        ul {
          pointer-events: none;
          list-style: none;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            pointer-events: auto;
            // cursor: pointer;
            height: 24px;
            font-size: 12px;
            color: #6c757deb;
            .showfilename {
              max-width: 130px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .filecheckbox {
              display: flex;
              align-items: center;
              margin-right: 10px;
              cursor: pointer;
              opacity: 0.65;
            }
          }
        }
      }
      .groupcontent,
      .selectcontent {
        max-height: 100px;
        background-color: #1876f2c7;
        border-radius: 5px;
        // padding: 10px 0;
        ul {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 26px;
            margin-left: 10px;
            color: #ffffff;
            .selectfilename {
              max-width: 140px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .draggablebtn {
              display: flex;
              align-items: center;
              width: 14px;
              margin-right: 8px;
              opacity: 0.5;
              cursor: pointer;
            }
            .draggablebtn:hover {
              opacity: 0.85;
            }
          }
        }
      }
      .groupcontent {
        background-color: #1897f2c7;
      }
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 100%;

    .morebtn {
      margin-top: 10px;
      cursor: pointer;
      opacity: 0.7;
    }
    .morebtn:hover {
      opacity: 1;
    }
  }
}

.groupcard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.1s;
  z-index: 901;
}
