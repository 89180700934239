.password-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 230px;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 26px;
  border: none;
  //   opacity: 0.95;
  z-index: 101;
  .password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .pwenterhead {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 2px;
      label {
        font-size: 14px;
        background: linear-gradient(
          to right,
          #3469e6,
          #19cca3,
          #ff8730,
          #f3376b
        ); /* 渐变色 */
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block;
      }
      .keyicon {
        width: 32px;
        opacity: 0.8;
        margin-bottom: 8px;
      }
    }
    input {
      width: 100%;
      font-size: 13px;
      padding: 8px;
      box-sizing: border-box;
      border: none;
      border-image: linear-gradient(
          to right,
          #f3376b,
          #ff8730,
          #19cca3,
          #3469e6
        )
        1;
      border-bottom: 1px solid;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 38px;
      align-items: center;
      font-size: 13px;
      color: #6c757d;
      background-color: #eaeaea94;
      margin-top: 10px;
      border-radius: 18px;
      box-sizing: border-box;
      border: none;
      text-align: center;
      cursor: pointer;
    }
    button:hover {
      color: rgb(255, 255, 255);
      background-color: #1876f2d7;
    }
  }
}

.overnewfilelayfile {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; /* 半透明黑色背景 */
  opacity: 0.15;
  visibility: visible;
  transition: 0.3s ease;
  z-index: 100;
}
