.infocard {
  width: 210px;
  max-height: 700px;
  background: #fff;
  position: fixed;
  top: 90px;
  left: 20px;
  //   background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  // box-shadow: 0px 6px 14px 4px rgba(0, 0, 0, 0.1);
  //   backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 15px;
  min-width: 0;
  font-size: 14px;

  border-radius: 3px;
  border: 1px solid #eaeaeabb;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  .infohead {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #eaeaeabb;
    .infodraggabletitle {
      font-size: 14px;
      color: #393c48;
      padding: 5px;
      opacity: 0.8;
      cursor: pointer;
    }
    .infodraggabletitle:hover {
      color: #393c48;
      opacity: 1;
    }
    .closeicon {
      opacity: 0.4;
      width: 18px;
      cursor: pointer;
    }
    .closeicon:hover {
      opacity: 0.8;
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 100%;
    // cursor: pointer;
    .morebtn {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .infoitems {
    .filekeyword {
      margin-top: 10px;
      label {
        color: #356ae6;
        // color: #1da1f2;
        font-size: 12px;
        // color: #393c48;
      }
      .keywordsinput {
        font-size: 13px;
        color: #6c757d;
        margin: 10px 0 2px 0;
        padding: 5px 1px;
        resize: none;
        border: none;
        opacity: 0.85;
        border-bottom: 0.5px solid #eaeaeabb;
      }
      .keywordsinput::placeholder {
        color: rgb(180, 180, 180);
        font-size: 12px;
      }
      .modelsetlayout {
        padding: 12px 0px;
        // color: #6c757d;

        width: 100%;
        .modelselect {
          // background-color: rgb(239, 85, 85);
          width: 100%;
          font-size: 13px;
          cursor: pointer;
          color: #6c757dda;
          border-bottom: 0.5px solid #eaeaeabb;
        }
        .modelselect:hover {
          color: #1da0f2a4;
        }
        .modeloptions {
          position: absolute;
          z-index: 1;
          margin-top: 8px;
          background-color: rgb(255, 255, 255);
          box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
          width: 86%;
          color: #6c757dda;
          .modeloption {
            cursor: pointer;
            font-size: 13px;
            padding: 5px 6px;
            height: 28px;
          }
          .modeloption:hover {
            color: #1da0f2a4;
            background-color: #fff;
          }
          .modeloption.selected {
            color: #1da1f2;
          }
        }
        .modeloptions:hover {
          box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
        }
      }
      .excelfileselect {
        padding: 12px 0px;
        // color: #6c757d;
        width: 100%;
        .dataexcelname {
          font-size: 12px;
          color: #1da0f2a4;
          align-items: center;
          margin-bottom: 10px;
        }
        .excelnamelayout {
          width: 100%;
          max-height: 100px;
          padding-bottom: 6px;
          // margin-bottom: 16px;
          overflow-y: auto;
          border: 0.5px solid #eaeaeaee;
          border-radius: 3px;
          ul {
            // pointer-events: none;
            list-style: none;
            padding: 0;
            .excelnameitem {
              display: flex;
              font-size: 12px;
              cursor: pointer;
              color: #6c757deb;
              align-items: center;
              justify-content: space-between;

              .showfilename {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 6px;
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden;
                // text-overflow: ellipsis;
              }

              .closeicon {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                margin-right: 5px;
                margin-top: 10px;
                cursor: pointer;
                opacity: 0.35;
                width: 14px;
              }
              .closeicon:hover {
                opacity: 0.8;
              }
            }
            .excelnameitem:hover {
              color: #1da0f2a4;
              // background-color: rgb(241, 247, 253);
            }
            .excelnameitem.selected {
              color: #1da0f2;
            }
          }
        }
        .excelfilelabel {
          display: flex;
          margin-top: 8px;
          margin-left: 6px;
          margin-bottom: 3px;
          color: #356ae6;
          font-size: 12px;
          cursor: pointer;
        }
        .excelfilelabel:hover {
          text-decoration: underline;
        }
        .selectdatafile {
          display: flex;
          align-items: center;
          .uploadedFileName {
            display: flex;
            // width: 130px;
            max-width: 130px;
            white-space: nowrap;
            overflow: hidden;
            margin-left: 6px;
            pointer-events: none;
            font-size: 11px;
            color: #1da0f2a4;
            align-items: center;
          }
          .closeicon2 {
            display: flex;
            margin-left: auto;
            margin-right: 12px;
            margin-top: 10px;
            cursor: pointer;
            opacity: 0.35;
            width: 14px;
          }
          .closeicon2:hover {
            opacity: 0.8;
          }
        }
        .handlefileinput {
          display: none;
        }
      }
      .button-group {
        display: flex;
        justify-content: center;
        .inforenewbutton,
        .infosetbutton,
        .infocancelbutton {
          width: 30%;
          height: 24px;
          border: none;
          margin: 12px 16px;
          border-radius: 2px;
          color: #6c757d;
          font-size: 11px;
          cursor: pointer;
        }
        .infosetbutton {
          font-size: 10px;
        }
        .inforenewbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #2261f3ab;
        }
        .infosetbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #19cca3;
        }

        .infocancelbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #f3376b;
        }
      }
    }
    .nodedesc {
      padding: 5px;
      font-size: 12px;
      color: #6c757d;
    }
    .infoshow {
      max-height: 150px;
      overflow: auto;
      margin: 2px 0 6px 0;
      transition: all 0.3s ease;
      .nodetitles .titlesshow {
        width: max-content;
        border: none;
        color: #1876f2;
        font-size: 12px;
        // border-bottom: 0.5px solid #1876f2;
        margin: 4px 0;
      }
      .nodedetails .detailsshow {
        border: none;
        margin: 5px 0;
      }
    }
    .infoshow::-webkit-scrollbar {
      width: 6px; /* 设置滚动条宽度 */
    }
    .infoshow::-webkit-scrollbar-track {
      background-color: transparent; /* 设置滚动条背景色为透明 */
    }

    .infoshow::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.05); /* 设置滚动条滑块颜色为半透明 */
      border-radius: 10px; /* 设置滑块边框半径 */
      cursor: pointer;
    }
    .nodeinfo {
      max-height: 150px;
      overflow: auto;
      margin: 2px 0 6px 0;
      transition: all 0.3s ease;
      .nodetitles .titlesinput {
        width: max-content;
        border: none;
        color: #1876f2;
        background: rgba(255, 255, 255, 0);
        font-size: 12px;
        // border-bottom: 0.5px solid #1876f2;
        margin: 4px 0;
      }
      .nodedetails .detailsinput {
        border: none;
        margin: 5px 0;
        background: rgba(255, 255, 255, 0);
      }
      .button-group {
        display: flex;
        justify-content: center;
        .infoaddbutton,
        .infosavebutton {
          width: 30%;
          height: 24px;
          border: none;
          margin: 12px 16px;
          border-radius: 2px;
          color: #6c757d;
          font-size: 11px;
          cursor: pointer;
        }
        .infoaddbutton:hover {
          color: rgb(255, 255, 255);
          background-color: #3b35e6ab;
        }

        .infosavebutton:hover {
          color: rgb(255, 255, 255);
          background-color: #2261f3ab;
        }
      }
    }
    .nodeinfo::-webkit-scrollbar {
      width: 6px; /* 设置滚动条宽度 */
    }
    .nodeinfo::-webkit-scrollbar-track {
      background-color: transparent; /* 设置滚动条背景色为透明 */
    }

    .nodeinfo::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.05); /* 设置滚动条滑块颜色为半透明 */
      border-radius: 10px; /* 设置滑块边框半径 */
    }
    .nodeimage {
      label {
        color: #356ae6;
        font-size: 12px;
        // color: #393c48;
      }
    }

    .nodeaiinfo,
    .nodeaudioinfo {
      margin-top: 12px;
      label {
        color: #356ae6;
        font-size: 12px;
        // color: #393c48;
      }
      .aipic,
      .audiopic {
        display: flex;
        margin-left: 150px;
        margin-top: -18px;
        // width: 30px;
        // height: 30px;
        background-color: transparent;
        // align-items: center;
        // justify-content: center;
        border-radius: 50%;
        transition: 0.3s;
        opacity: 0.5;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          align-self: center;
        }
      }
      .audiolist {
        .listitems {
          list-style-type: none;
          margin-top: 5px;
          // font-size: 12px;
          color: #6c757d;
          border: 0.5px solid #eaeaeaee;
          .listitem {
            display: flex;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 6px;
            padding-left: 6px;
            // cursor: pointer;
            .audiotitle {
              font-size: 12px;
              cursor: pointer;
            }
            .closeicon2 {
              display: flex;
              margin-left: auto;
              margin-right: 12px;
              // margin-top: 10px;
              cursor: pointer;
              opacity: 0.25;
              width: 14px;
            }
            .closeicon3 {
              display: flex;
              margin-left: auto;
              margin-right: 12px;
              // margin-top: 10px;
              cursor: pointer;
              opacity: 0.65;
              width: 16px;
            }
            .closeicon2:hover {
              opacity: 0.65;
            }
          }
          .listitem:hover {
            background-color: #e7f5fe9a;
            color: #1da1f2;
          }
        }
      }
      .aipic:hover,
      .audiopic:hover {
        // background-color: #66d2f925;
        transition: 0.3s;
        opacity: 0.8;
      }
      input {
        font-size: 13px;
        margin-top: 10px;
        background: rgba(255, 255, 255, 0);
      }
    }
    .username {
      font-size: 12px;
      color: #6c757d;
    }

    .date {
      color: #6c757d;
      font-size: 12px;
    }
    span {
      padding-top: 10px;
      padding-right: 10px;
    }
    .aiinput {
      margin-top: 6px;
      border: none;
      border-bottom: 0.5px solid #eaeaeabb;
    }

    .speak-bottom {
      display: flex;
      height: 50px;
      // margin-top: 13px;
      // align-items: center;
      // justify-content: space-between;
      .bottom-left {
        display: flex;
        // display: inline-flex;
        // padding-left: 0px;
        align-items: center;
        .speakicons {
          color: #1da1f2;
          font-size: 14px;
          // margin: 0 2px;
          height: 26px;
          width: 26px;
          margin-right: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: background 0.2s ease;
          .icons4 {
            font-size: 12px;
          }
          .icons5 {
            width: 16px;
            opacity: 0.65;
          }
        }
        .speakicons:hover {
          background: #e7f5fe;
          .icons5 {
            opacity: 0.9;
          }
        }
        .number {
          display: flex;
          font-size: 12px;
          padding-top: 4px;
          color: #6c757d;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        .counter {
          color: #333;
          display: none;
          font-weight: 500;
          margin-right: 15px;
          padding-right: 15px;
          border-right: 1px solid #aab8c2;
        }
        button {
          margin-right: 5px;
          padding: 4px 16px;
          border: none;
          outline: none;
          border-radius: 50px;
          font-size: 12px;
          // font-weight: 600;
          background: #1da1f2;
          color: #fff;
          cursor: pointer;
          opacity: 0.5;
          pointer-events: none;
          transition: background 0.2s ease;
        }
        button.active {
          opacity: 1;
          pointer-events: auto;
        }
        button:hover {
          background: #0d8bd9;
        }
      }
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 100%;

    .morebtn {
      margin-top: 10px;
      cursor: pointer;
      opacity: 0.7;
    }
    .morebtn:hover {
      opacity: 1;
    }
  }
}

.infocard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.1s;
  z-index: 901;
}
