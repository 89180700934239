.groupfilelayout {
  display: flex;
  width: 100%;
  height: 100vh;
  .loaduser {
    display: flex;
    position: absolute;
    top: 36px;
    right: 16px;
    //  width: 36px;
    //   height: 36px;
    border-radius: 50%;
    z-index: 102;
    .useritem {
      display: flex;
      align-items: center;
      margin-left: 16px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #e4e8f1;
      // background-color: transparent;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.85;
      cursor: pointer;
      .userimage {
        width: 32px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    .useritem:hover {
      background-color: #b9ccf7;
    }
  }
  .groupname {
    display: flex;
    position: absolute;
    top: 18px;
    width: 100%;
    height: 50px;
    // background-color: #fff;
    align-items: center;
    justify-content: center;
    z-index: 101;
    .groupnameedit {
      height: 40px;
      padding-left: 10px;
      border: none;
      color: #356ae6cd;
      font-size: 16px;
    }
    .showname {
      display: flex;
      color: #356ae6cd;
      font-size: 22px;
      font-weight: 600;
      width: 100%;
      align-items: center;
      justify-content: center;
      letter-spacing: 2px;
      // max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .groupmenuicon {
    display: flex;
    position: absolute;
    align-items: center;
    top: 74px;
    left: 72px;
    opacity: 0.65;
    .grouplisticon {
      // display: flex;
      // position: relative;
      margin-right: 8px; // 添加右边距，将图标与文本分隔开
    }
  }

  .custom-dropdown {
    display: flex;
    position: absolute;
    align-items: center;
    top: 56px;
    left: 100px;
    width: 200px;

    .custom-dropdown-toggle {
      cursor: pointer;
      margin-top: 10px;
      padding: 8px 16px;
      width: fit-content;
      border: none;
      //   border-bottom: 0.5px solid #eaeaeabb;
      //   border-bottom: 0.5px solid #eaeaeabb;
      color: #356ae6c7;
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
      opacity: 0.85;
    }
    .custom-dropdown-toggle:hover {
      // box-shadow: 0 2px 10px rgba(183, 183, 183, 0.25);
      box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
      //   color: #356ae6;
    }

    .dropdownlayout {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      .custom-dropdown-options {
        width: 100%;
        height: 100%;
        color: #6c757dda;
        // color: #202020;
        font-size: 12px;
        background-color: #ffffffe7;
        border: 0.5px solid #eaeaeabb;
        border-top: none;
        // display: flex;
        flex-direction: column;
        z-index: 902;
        .custom-dropdown-option {
          height: 28px;
          // margin: 0 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 6px;
          cursor: pointer;
          &:hover {
            // background-color: #f0f0f0;
            color: #356ae6;
          }
          &.selected {
            background-color: #1897f2c7;
            color: #fff;
            &:hover {
              background-color: #1897f2c7;
            }
          }
        }
      }
      .custom-dropdown-options:hover {
        // box-shadow: 0 2px 10px rgba(183, 183, 183, 0.25);
        box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
      }
    }
  }
  .groupfileitem {
    display: flex;
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 1000px) {
  .groupfilelayout {
    .groupmenuicon {
      left: 32px;
    }

    .custom-dropdown {
      left: 60px;
      .custom-dropdown-toggle {
        padding: 8px 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .groupfilelayout {
    .groupname {
      top: 5px;
      .showname {
        font-size: 16px;
      }
    }
    .groupmenuicon {
      top: 55px;
      left: 12px;
      .grouplisticon {
        width: 20px;
      }
    }

    .custom-dropdown {
      top: 35px;
      left: 34px;

      .custom-dropdown-toggle {
        padding: 10px 8px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
