@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  /* border: none; */
  outline: none;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 25px;
}
::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 25px;
  border: 0.5rem solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(204, 204, 204, 0.38);
}
