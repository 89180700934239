.searchBox {
  position: absolute;
  bottom: 0px;
  right: -10px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.2);
}
 .searchBox:hover {
    // background-color: rgba(230, 244, 255, 0.8);
    box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
  }
.searchBox.active {
  transform: translateX(-2px);
  width: 300px;
}
.searchBox .search {
  position: relative;
  min-width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
}
.searchBox .search .searchimg {
  width: 24px;
  height: 24px;
  opacity: 1;
}
.searchBox .close {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.25em;
  scale: 0;
  transition: 0.5s;
}
.searchBox .close .closeimg {
  width: 24px;
  height: 24px;
  opacity: 60%;
}
.searchBox.active .close {
  scale: 1;
  transition-delay: 0.3s;
}
.searchBox .searchInput {
  position: absolute;
  left: 45px;
  width: calc(100% - 90px);
  
  height: 70%;
  line-height: 60px;
}
.searchBox .searchInput input {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 14px;
}
.searchBox .searchInput input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}