input,
textarea {
  user-select: text;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #9ca2b52c; /* 半透明黑色背景 */
  // background-color: rgba(0, 0, 0, 0.025); /* 半透明黑色背景 */
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  // display: none;
  z-index: 100;
}
.overlay.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
  // display: block;
  z-index: 100;
}

.popuplayout.login {
  position: absolute;
  box-sizing: border-box;
  z-index: 200;
  padding: 0;
  min-width: 0;
  font-size: 12px;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 450px;
  border-radius: 3px;
  margin-left: -200px;
  margin-top: -225px;
  border: 1px solid #eaeaea83;
  background-color: #fff;
  overflow: hidden;
  // box-shadow: 0px 6px 14px 4px rgb(17 71 162 / 4%);
  box-shadow: 0px 6px 10px 4px rgb(17 71 162 / 4%),
    0px 6px 10px 4px rgb(17 71 162 / 4%);

  opacity: 0;
  visibility: hidden;
  transform: scale(1);
  transition: 0.3s ease;

  .popupbody.login {
    align-items: center;
    width: 100%;
    position: relative;
    padding: 8px 0 8px;
    .sign-content {
      margin: 0 auto;
      text-align: center;
      width: 350px;
      height: 365px;
      input[type="radio"] {
        display: none;
      }
      nav {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .loginmenu {
          display: flex;
          width: 80%;
          height: 100%;
          font-size: 16px;
          color: #7b7b7b;
          justify-content: space-between;
          label {
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;
            margin: 0 20px;
            text-align: center;
            transition: all 0.3s ease;
          }
          label:hover {
            color: #3469e6;
          }
        }
        .sliderlogin {
          position: absolute;
          height: 2px;
          width: 50%;
          left: 0;
          bottom: 0;
          background-color: #3469e6;
          opacity: 0.8;
          transition: all 0.3s ease;
        }
      }

      section .popupitems {
        width: 100%;
        margin-top: 5px;
        display: none;
        .login-item {
          display: flex;
          position: relative;
          font-size: 15px;
          height: 70px;
          width: 100%;
          bottom: 0;
          .input-account,
          .input-password {
            line-height: 45px;
            margin-top: 25px;
            width: 100%;
            border: none;
            outline: none;
            border-bottom: 1px solid #eaeaea;
            input {
              display: flex;
              margin-left: 5px;
              font-size: 15px;
              height: 36px;
              width: 80%;
              border: none;
              outline: none;
              opacity: 0.8;
            }
            input::placeholder {
              font-size: 14px;
              opacity: 0.5;
            }
          }
          .input-account.register,
          .input-password.register {
            position: relative;
            line-height: 30px;
            margin-top: 25px;
            input {
              height: 26px;
            }
          }

          .error-msg,
          .pserror-msg,
          .accounterror-msg {
            position: absolute;
            right: 0;
            top: 15px;
            color: #f3376b;
            font-size: 12px;
            opacity: 0.8;
          }
          .accountsuccess-msg,
          .success-msg {
            position: absolute;
            right: 0;
            top: 15px;
            color: #19cca3;
            font-size: 12px;
            opacity: 0.8;
          }
          .accounterror2-msg,
          .showloginerror-msg {
            position: absolute;
            right: 0;
            top: 38px;
            color: #f3376b;
            font-size: 12px;
            opacity: 0.8;
          }
          .showlogin-msg {
            position: absolute;
            right: 0;
            top: 38px;
            color: #19cca3;
            font-size: 12px;
            opacity: 0.8;
          }

          button {
            width: 100%;
            height: 36px;
            font-size: 14px;
            background-color: #3469e6;
            color: #fff;
            border: none;
            border-radius: 3px;
            cursor: pointer;
          }
          button:hover {
            background-color: #1b54d9;
          }
          .showicons {
            display: flex;
            font-size: 20px;
            color: #898989;
            position: absolute;
            bottom: 17px;
            right: 15px;
            cursor: pointer;
          }
          .showicons.off {
            display: none;
          }
          .showicons:hover {
            color: #3469e6;
          }
          .showtext {
            display: flex;
            font-size: 14px;
            color: #3469e6;
            position: absolute;
            bottom: 5px;
            right: 15px;
            cursor: pointer;
          }
          .showtext:hover {
            color: #8590a6;
          }
        }
        .login-item.register {
          height: 65px;
        }

        .login-item.setpw {
          display: flex; //-----xxx
          align-items: center; //
          text-align: justify; //
          padding-top: 10px;
          margin-bottom: 7px;
          height: 40px;
          font-size: 12px;
          opacity: 0.85;
          span {
            margin-left: 5px;
            color: #8590a6;
          }
        }
        .login-item.forget {
          padding-top: 10px;
          margin-bottom: 12px;
          height: 40px;
          font-size: 12px;
          span {
            margin-left: 5px;
            color: #3469e6;
            opacity: 0.8;
            cursor: pointer;
          }
          span:hover {
            color: #8590a6;
          }
        }
        .login-item.button {
          height: 50px;
        }
        .login-item.hide {
          display: flex;
          align-items: center;
          text-align: justify;
          height: 50px;
          font-size: 12px;
          color: #8590a6;
          opacity: 0.85;
          span {
            margin: 2px;
          }
        }
      }
    }
    .css-line {
      margin: 0 25px 0 25px;
      width: 350px;
      height: 1px;
      background: #eaeaea;
    }
    .footsign {
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      color: #898989;
      padding: 0 24px;
      box-sizing: border-box;
      .social-title {
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 100px;
      }
      .social-group {
        display: flex;
        width: 100%;
        .social-item {
          display: flex;
          width: 70px;
          font-size: 20px;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          span {
            font-size: 13px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

#pwfree:checked ~ nav label.pwfree,
#pwlogin:checked ~ nav label.pwlogin {
  color: #3469e6;
}
#pwlogin:checked ~ nav .sliderlogin {
  left: 50%;
}
#pwfree:checked ~ section .pwfree,
#pwlogin:checked ~ section .pwlogin {
  display: block;
}

.popupitems.pwfree.active,
.popupitems.pwlogin.active {
  display: block;
}

.popuplayout.login.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: 0.35s ease;
}
