.wrapper {
  display: flex;
  max-width: 1200px;
  position: relative;
  margin: 12px 10px 8px 10px;
}
.wrapper .icon {
  top: 50%;
  height: 20px;
  width: 20px;
  color: #343f4f;
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  text-align: center;
  line-height: 20px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
  opacity: 0.25;
}
.wrapper .icon:active {
  transform: translateY(-50%) scale(0.9);
}
.wrapper .icon:hover {
  background: #f2f2f2;
}
.wrapper .icon:first-child {
  left: -22px;
  display: block;
}
.wrapper .icon:last-child {
  right: -22px;
}
.wrapper .carousel {
  font-size: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.wrapper .carousel.dragging {
  cursor: grab;
  scroll-behavior: auto;
}
.wrapper .carousel.dragging img {
  pointer-events: none;
}
.wrapper .carousel img {
  height: 100px;
  object-fit: cover;
  user-select: none;
  margin-left: 14px;
  width: calc(100% / 1);
}
.wrapper .carousel img:first-child {
  margin-left: 0px;
}

@media screen and (max-width: 900px) {
  .wrapper .carousel img {
    width: calc(100% / 1);
  }
}

@media screen and (max-width: 550px) {
  .wrapper .carousel img {
    width: 100%;
  }
}
.upload-wrapper {
  input {
    display: none;
  }
  label {
    .uploadicon {
      display: flex;
      margin-left: 150px;
      margin-top: -28px;
      // width: 30px;
      // height: 30px;
      background-color: transparent;
      // align-items: center;
      // justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      opacity: 0.45;
      img {
        width: 18px;
        height: 18px;
        align-self: center;
      }
    }
    .uploadicon:hover {
      // background-color: #66d2f925;
      transition: 0.3s;
      opacity: 0.8;
    }
  }
}
