.titletable{
    position: fixed;
    top: 60px;
    width: 100%;
    .table-top{
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    }

.table {
    
    .cellWrapper {
      display: flex;
      align-items: center;
  
      .image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  
    .status {
      padding: 5px;
      border-radius: 5px;
  
      &.Approved {
        color: green;
        background-color: rgba(0, 128, 0, 0.151);
      }
      &.Pending {
        color: goldenrod;
        background-color: rgba(189, 189, 3, 0.103);
      }
    }
    .icon-table {
      display: flex;
      cursor: pointer;
      width: 36px;
      height: 36px;
      margin-right: 8px;
      float: left;
      border-radius: 50%;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      opacity: 0.35;
      img {
        width: 22px;
        height: 22px;
        opacity: 0.85;
      }
    }
    .icon-table:hover {
      background-color: #66d2f944;
      transition: 0.3s;
      opacity: 0.7;
    }
  }
}