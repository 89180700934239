
.datacard {
  width: 250px;
  max-height: 700px;
  background: #fff;
  position: fixed;
  top: 90px;
  left: 20px;
  // box-shadow: 0px 6px 15px 4px rgb(17 71 162 / 5%);
  box-shadow: 0 2px 10px rgba(16, 40, 255, 0.12);
  // backdrop-filter: blur(25px);
  z-index: 900;
  // position: absolute;
  box-sizing: border-box;
  padding: 8px 15px;
  min-width: 0;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;

  opacity: 0.3;
  visibility: hidden;
  transition: 0.1s;

  flex-direction: column;
  justify-content: space-around;
  cursor: grab;
  .datahead {
    display: flex;
    justify-content: space-between;
    // border-bottom: 0.5px solid #eaeaeabb;
    // border-bottom: 0.5px solid #eaeaea81;
    .datadraggabletitle {
      display: flex;
      font-size: 13px;
      // width: 158px;
      // min-height: 20px;
      // max-width: 158px;
      color: #007cee;
      // background-color: #f0f0f0;
      padding: 5px;
      opacity: 0.8;
      cursor: pointer;
      word-wrap: break-word;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-word;
      transition: height 0.3s ease;
      will-change: height;
      transform: translateZ(0);
      // display: none;
    }
    .datadraggabletitle:hover {
      color:#1876f2;
      opacity: 1;
    }
    .closeicon {
      opacity: 0.4;
      width: 18px;
      cursor: pointer;
    }
    .closeicon:hover {
      opacity: 0.8;
    }
  }
  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 100%;
    // cursor: pointer;
    .morebtn {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .dataitems {
    // border-top: 0.5px solid #eaeaea98;
    border-top: 0.5px solid #eaeaeabb;
    overflow-y: auto;
    max-height: 500px;
    transition: height 1s ease;
    .nodedatalist {
      margin-top: 6px;
      margin-bottom: 10px;
      label {
        color: #6c757d84;
        // color: #6c757d74;
        // color: #1da1f2;
        font-size: 10px;
        margin-left: 8px;
      }
      .nodedataitem {
        margin-top: 10px;
        margin-left: 8px;
        .datatitle {
          font-size: 11px;
          letter-spacing: 1px;
          // color: #1da0f2a4;
          color: #1876f2;
          opacity: 0.8;
        }
        .datadetail {
          margin-top: 6px;
          font-size: 13px;
          // cursor: pointer;
          color: #6c757d;
          align-items: center;
        }
      }
    }
  }
  .nodeaudiolist {
   // margin-top: 10px;
   // margin-bottom: 8px;
    .audiolist {
      border-top: 0.5px solid #eaeaeabb;
      overflow-y: auto;
      max-height: 500px;
      transition: height 1s ease;
      padding-top: 6px;
      label {
        color: #6c757d84;
        // color: #6c757d74;
        // color: #1da1f2;
        font-size: 10px;
        margin-left: 8px;
      }
      .listitems {
        list-style-type: none;
        margin-top: 5px;
        font-size: 12px;
        color: #6c757d;
        // border: 0.5px solid #eaeaeaee;
        .listitem {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 10px;
          cursor: pointer;
          .closeicon2 {
            display: flex;
            margin-left: auto;
            // margin-right: 2px;
            // margin-top: 10px;
            cursor: pointer;
            opacity: 0.5;
            width: 16px;
          }
          .closeicon3 {
            display: flex;
            margin-left: auto;
            // margin-right: 2px;
            // margin-top: 10px;
            cursor: pointer;
            opacity: 0.65;
            width: 18px;
          }
          .closeicon2:hover {
            opacity: 0.65;
          }
        }
        .listitem:hover {
          // background-color: #e7f5fe9a;
          color: #1da1f2;
        }
      }
    }
  }

  .info-bottom {
    display: flex;
    height: 42px;
    // margin-top: 13px;
    // align-items: center;
    // justify-content: space-between;
    .bottom-left {
      display: flex;
      // display: inline-flex;
      // padding-left: 0px;
      align-items: center;
      .speakicons {
        color: #1da1f2;
        font-size: 14px;
        // margin: 0 2px;
        height: 26px;
        width: 26px;
        margin-right: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background 0.2s ease;
        .icons4 {
          font-size: 12px;
        }
        .icons5 {
          width: 16px;
          opacity: 0.65;
        }
        .icons6 {
          width: 12px;
          opacity: 0.65;
        }
      }
      .speakicons:hover {
        background: #e7f5fe;
        .icons5 {
          opacity: 0.9;
        }
        .icons6 {
          opacity: 0.9;
        }
      }
      .number {
        display: flex;
        font-size: 12px;
        padding-top: 4px;
        color: #6c757d;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      .counter {
        color: #333;
        display: none;
        font-weight: 500;
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid #aab8c2;
      }
      button {
        margin-right: 5px;
        padding: 4px 16px;
        border: none;
        outline: none;
        border-radius: 50px;
        font-size: 12px;
        // font-weight: 600;
        background: #1da1f2;
        color: #fff;
        cursor: pointer;
        opacity: 0.5;
        pointer-events: none;
        transition: background 0.2s ease;
      }
      button.active {
        opacity: 1;
        pointer-events: auto;
      }
      button:hover {
        background: #0d8bd9;
      }
    }
  }

  .bottombotton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 8px;
    width: 100%;

    .morebtn {
      margin-top: 10px;
      cursor: pointer;
      opacity: 0.7;
    }
    .morebtn:hover {
      opacity: 1;
    }
  }
}

.dataitems::-webkit-scrollbar {
  width: 4px; /* 设置滚动条宽度 */
}
.dataitems::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滚动条背景色为透明 */
}

.dataitems::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.03); /* 设置滚动条滑块颜色为半透明 */
  border-radius: 10px; /* 设置滑块边框半径 */
}

.datacard.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(-15px);
  transition: 0.1s;
  z-index: 901;
}

@media (max-width: 760px) {
  .datacard.active {
    width: 210px;
    .datahead {
      // height: 8px;
      // background-color: rgb(203, 196, 196);
      cursor: pointer;
      .datadraggabletitle {
        // opacity: 0;
        // display: none;
        // height: 20px;
        font-size: 11px;
        //color: #6c757d;
        color: #007cee !important;
      }
      //.closeicon {
      // display: none;
      //}
    }
    .dataitems {
      border: none;
      .nodedatalist {
        margin-top: 0px;
        margin-bottom: 6px;
        label {
          // font-size: 11px;
          display: none;
        }
        .nodedataitem {
          .datatitle {
            font-size: 11px;
          }
          .datadetail {
            font-size: 12px;
          }
        }
      }
    }
    .nodeaudiolist {
      .audiolist {
        label {
          // font-size: 11px;
          display: none;
        }
      }
    }
    .info-bottom {
      .bottom-left {
        .speakicons {
          margin-right: 5px;
        }
      }
    }
  }
}
